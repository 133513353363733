import React, { Component } from 'react';
import * as queryString from 'querystring';

import { AuthService } from '../services/authService';
import { FreshTokens } from '../models/freshTokens';

import './callback.scss';

export class Callback extends Component<any, any> {
  authService = new AuthService();

  constructor(prop: any) {
    super(prop);
    const tokens = queryString.parse(this.props.location.search.substring(1));

    try {
      this.authService.parseFreshTokens((tokens as unknown) as FreshTokens);
      this.props.history.push('/');
      window.location.reload();
    } catch (e) {
      this.props.history.push('/login');
    }
  }

  render(): JSX.Element {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }
}
