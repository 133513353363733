/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftHandleValue,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  RichUtils
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {
  MultiHighlightConfig,
  MultiHighlightDecorator,
  SentenceMatcher,
  WordMatcher
} from 'draft-js-multi-inline-highlight';
import * as MultiDecorator from '@qnighy/draft-js-multidecorators';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import React, { createRef, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useGlobalState } from './components/shared/GlobalContext';
import { normalizeHTML } from './components/shared/htmlTransformer';
import { goToastError } from './components/shared/Toasts';
import { Analytics } from './nlp-models/analytics.model';
import { Mode } from './nlp-models/mode.enum';
import './scss/editor.scss';
import { ErrorData } from './shared';
import { MsgCantRenameDocument } from './shared/constants/messages';
import { getErrorContent } from './shared/functions/process-error';
import { Toolbar } from './Toolbar';

const highlightStyles = {
  DifficultSentence: {
    borderBottomWidth: 3,
    borderBottomColor: '#ec8986',
    borderBottomStyle: 'solid'
  },
  PassiveSentence: {
    display: 'inline',
    backgroundColor: '#f4ebd3'
  },
  DifficultWord: {
    backgroundColor: '#ffb8b8'
  },
  VagueWord: {
    backgroundColor: '#d1c4e9'
  },
  AdverbsWord: {
    backgroundColor: '#aae3eb'
  },
  UsageAnd: {
    backgroundColor: '#c8d7ff'
  }
};

const initHighlightConfig: MultiHighlightConfig = {
  rules: [
    { content: [], style: 'DifficultSentence', matcher: SentenceMatcher },
    { content: [], style: 'PassiveSentence', matcher: SentenceMatcher },

    { content: [], style: 'DifficultWord', matcher: WordMatcher },
    { content: [], style: 'VagueWord', matcher: WordMatcher },
    {
      content: [],
      style: 'AdverbsWord',
      matcher: WordMatcher
    },
    {
      content: [],
      style: 'UsageAnd',
      matcher: WordMatcher
    }
  ],
  styles: highlightStyles
};

enum BlockCommand {
  H1 = 'header-one',
  H2 = 'header-two',
  H3 = 'header-three',
  H4 = 'header-four',
  H5 = 'header-five',
  H6 = 'header-six',
  OL = 'ordered-list-item',
  UL = 'unordered-list-item'
}

const blockCommands: string[] = [
  BlockCommand.H1,
  BlockCommand.H2,
  BlockCommand.H3,
  BlockCommand.H4,
  BlockCommand.H5,
  BlockCommand.H6,
  BlockCommand.OL,
  BlockCommand.UL
];

export interface TextContentContentBlocks {
  text: string;
  blocks: string[];
}

export const getHtmlContent = (_editorState: EditorState): string => stateToHTML(_editorState.getCurrentContent());
export const getTextContent = (_editorState: EditorState): TextContentContentBlocks => {
  const blocks: string[] = [];
  const paragraphs: string[] = [];
  const blocksArray = _editorState.getCurrentContent().getBlocksAsArray();
  for (let i = 0; i < blocksArray.length; i++) {
    blocks.push(blocksArray[i].getKey());
    paragraphs.push(blocksArray[i].getText().replace(/\[\s*\d+\s*\]/g, ''));
  }
  return {
    blocks,
    text: paragraphs.join('\n\n')
  };
};

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a
      href={url}
      target="_blank"
      style={{
        color: '#3b5998',
        textDecoration: 'underline'
      }}
    >
      {props.children}
    </a>
  );
};

export function MyEditor(props: {
  initContent: string;
  activeMode: Mode;
  onContentInit: (editorState: EditorState) => void;
  onContentChange: (editorState: EditorState, processImediately?: boolean) => void;
  onContentAnalyze: (editorState: EditorState) => void;
  title: string;
  id?: number;
  documentHash: string;
  saveFlag: boolean;
  readOnly?: boolean;
  analytics: Analytics;
  onDocNameChanged?: (name: string) => void;
}) {
  const { account } = useGlobalState();
  const {
    initContent,
    onContentInit,
    onContentChange,
    onContentAnalyze,
    title,
    id,
    documentHash,
    saveFlag,
    readOnly,
    activeMode,
    analytics,
    onDocNameChanged
  } = props;
  const editor = createRef<any>();
  const processedHTML = DraftPasteProcessor.processHTML(initContent);
  const contentState = ContentState.createFromBlockArray(processedHTML);
  const [currentHighlightConfig, setCurrentHighlightConfig] = useState<MultiHighlightConfig>(initHighlightConfig);
  const [persistentHighlightConfig, setPersistentHighlightConfig] = useState<MultiHighlightConfig>();
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(contentState, MultiHighlightDecorator(currentHighlightConfig))
  );
  const [currentText, setCurrentText] = useState<TextContentContentBlocks>();
  const [contentToCheck] = useDebounce(currentText?.text, 1500);
  const [pasteEvent, setPasteEvent] = useState<boolean>(false);
  const [docName, setDocName] = useState<string>(title);
  const [editableDocName, setEditableDocName] = useState<string | undefined>();
  const [renamingDoc, setRenamingDoc] = useState<boolean>(false);

  const linkDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link
    }
  ]);

  const focus = () => {
    editor.current.focus();
  };

  const renameDocument = (id: number, title: string) => {
    if (title) {
      axios
        .post(`/document/rename/${id}`, JSON.stringify({ title }))
        .then(() => {
          setDocName(title);
          setEditableDocName(undefined);
          if (onDocNameChanged) {
            onDocNameChanged(title);
          }
        })
        .catch((error: { response: { data: ErrorData } } | ErrorData) => {
          goToastError(getErrorContent(error, MsgCantRenameDocument));
        })
        .finally(() => {
          setRenamingDoc(false);
        });
    }
  };
  const keyBindingFn = (event: any) => {
    let blockCommand;
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 49) {
      blockCommand = BlockCommand.H1;
    }
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 50) {
      blockCommand = BlockCommand.H2;
    }
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 51) {
      blockCommand = BlockCommand.H3;
    }
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 55) {
      blockCommand = BlockCommand.OL;
    }
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 56) {
      blockCommand = BlockCommand.UL;
    }
    if (blockCommand) {
      return blockCommand;
    }

    return getDefaultKeyBinding(event);
  };
  const handleKeyCommand = (command: string, editorState: EditorState): DraftHandleValue => {
    let newEditorState;
    if (blockCommands.includes(command)) {
      newEditorState = RichUtils.toggleBlockType(editorState, command);
    } else {
      newEditorState = RichUtils.handleKeyCommand(editorState, command);
    }
    if (newEditorState) {
      setEditorState(newEditorState);
      return 'handled';
    }
    return 'not-handled';
  };
  const handlePastedText = (text: string, html: string | undefined, editorState: EditorState): DraftHandleValue => {
    const cleanHtml = normalizeHTML(html || text);
    const processedHTML = DraftPasteProcessor.processHTML(cleanHtml);
    const contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(processedHTML).getBlockMap()
    );

    const newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
    setEditorState(newEditorState);
    setPasteEvent(true);
    setCurrentText(getTextContent(editorState));
    return 'handled';
  };

  const onChange = (_editorState: EditorState) => {
    setEditorState(_editorState);
    const isPasteAction = _editorState.getLastChangeType() === 'insert-fragment';
    const isRemoveRange = _editorState.getLastChangeType() === 'remove-range';
    onContentChange(_editorState, isPasteAction || isRemoveRange);
    if (activeMode === Mode.WRITE) {
      return;
    }
    if (isPasteAction || isRemoveRange) {
      onContentAnalyze(_editorState);
    }
    const newText = getTextContent(_editorState);
    if (currentText && currentText.text === newText.text) {
      return;
    }
    setCurrentText(getTextContent(_editorState));
  };

  const toggleType = (blockType: string, type: 'block' | 'inline'): void => {
    if (type === 'block') {
      const newState = RichUtils.toggleBlockType(editorState, blockType);
      setEditorState(newState);
    }

    if (type === 'inline') {
      const newState = RichUtils.toggleInlineStyle(editorState, blockType);
      setEditorState(newState);
    }
  };

  const blockStyleFn = (block: ContentBlock): string => {
    if (block.getType() === 'code-block') {
      return '';
    }
    if (activeMode === Mode.WRITE) {
      return 'normalParagraph';
    }

    return analytics.paragraphs.difficult.includes(block.getKey()) ? 'difficultParagraph' : 'normalParagraph';
  };

  useEffect(() => {
    if (activeMode === Mode.WRITE) {
      setCurrentHighlightConfig(initHighlightConfig);
      setPersistentHighlightConfig(initHighlightConfig);
      setEditorState(
        EditorState.set(editorState, {
          decorator: new MultiDecorator([linkDecorator, MultiHighlightDecorator(initHighlightConfig)])
        })
      );
      return;
    }
    const newHighlightConfig = {
      rules: [
        { content: analytics.sentences.difficult as any, style: 'DifficultSentence', matcher: SentenceMatcher },
        { content: analytics.sentences.passive, style: 'PassiveSentence', matcher: SentenceMatcher },
        { content: analytics.words.difficult, style: 'DifficultWord', matcher: WordMatcher },
        {
          content: account?.features?.includes('vague-words') ? analytics.words.vague : [],
          style: 'VagueWord',
          matcher: WordMatcher
        },
        {
          content: analytics.words.adverb,
          style: 'AdverbsWord',
          matcher: WordMatcher
        },
        {
          content: analytics.usageAnd.percentage < 4 ? [] : [analytics.usageAnd.word],
          style: 'UsageAnd',
          matcher: WordMatcher
        }
      ],
      styles: highlightStyles
    };
    setCurrentHighlightConfig(newHighlightConfig);
    setPersistentHighlightConfig(newHighlightConfig);
    setEditorState(
      EditorState.set(editorState, {
        decorator: new MultiDecorator([linkDecorator, MultiHighlightDecorator(newHighlightConfig)])
      })
    );
  }, [analytics]);

  useEffect(() => {
    if (activeMode === Mode.ANALYZE) {
      if (persistentHighlightConfig !== undefined) {
        setCurrentHighlightConfig(persistentHighlightConfig);
        setEditorState(
          EditorState.set(editorState, {
            decorator: new MultiDecorator([linkDecorator, MultiHighlightDecorator(persistentHighlightConfig)])
          })
        );
      }
    }
    if (activeMode === Mode.WRITE) {
      setCurrentHighlightConfig(initHighlightConfig);
      setEditorState(
        EditorState.set(editorState, {
          decorator: new MultiDecorator([linkDecorator, MultiHighlightDecorator(initHighlightConfig)])
        })
      );
    }

    if (pasteEvent && activeMode === Mode.ANALYZE) {
      setPasteEvent(false);
      onContentAnalyze(editorState);
    }
  }, [pasteEvent, activeMode, persistentHighlightConfig]);

  useEffect(() => {
    if (activeMode !== Mode.ANALYZE || contentToCheck === undefined) {
      return;
    }
    onContentAnalyze(editorState);
  }, [contentToCheck]);

  useEffect(() => {
    focus();
    onContentInit(editorState);
  }, []);

  return (
    <div className="editor-content">
      <div className="doc-title">
        {saveFlag ? (
          <img src={require('./assets/images/save-document.svg')} alt="Document" />
        ) : (
          <img src={require('./assets/images/document.svg')} alt="Document" />
        )}
        {readOnly && <>{title}</>}
        {!readOnly && editableDocName === undefined && (
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              if (readOnly) {
                return;
              }
              setEditableDocName(docName);
            }}
          >
            {docName}
          </a>
        )}
        {!readOnly && editableDocName !== undefined && (
          <div className="rename-doc-container">
            <div className="rename-doc-content">
              <input
                value={editableDocName}
                onChange={e => {
                  setEditableDocName(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  setRenamingDoc(true);
                  renameDocument(id || 0, editableDocName);
                }}
                disabled={renamingDoc || editableDocName === ''}
                variant="contained"
                color="primary"
                style={{ width: 130 }}
                className="doc-title-button-generic"
              >
                {renamingDoc ? 'Processing...' : 'Rename'}
              </Button>
              <Button
                onClick={() => {
                  setEditableDocName(undefined);
                }}
                variant="outlined"
                color="primary"
                className="doc-title-button-generic"
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
      {!readOnly && <Toolbar editorState={editorState} onToggle={toggleType} documentHash={documentHash} />}
      <div id="editor">
        <div className="editor" onClick={focus}>
          <Editor
            ref={editor}
            readOnly={readOnly}
            blockStyleFn={blockStyleFn}
            editorState={editorState}
            onChange={onChange}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={keyBindingFn}
            handlePastedText={handlePastedText}
          />
        </div>
      </div>
    </div>
  );
}

export default MyEditor;
