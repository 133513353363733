export const VAGUE_WORDS = new Set<string>([
  'approach', 'approaches', 'assumption', 'assumptions',
  'concept', 'concepts', 'condition', 'conditions',
  'context', 'contexts', 'framework', 'frameworks',
  'issue', 'issues', 'process', 'processes',
  'range', 'role', 'roles', 'strategy',
  'strategies', 'tendency', 'tendencies', 'variable',
  'variables', 'perspective', 'perspectives', 'accrual',
  'derivative', 'derivatives', 'fair value', 'portfolio',
  'portfolios', 'audit', 'poverty', 'evaluation',
  'evaluations', 'management', 'monitoring', 'effectiveness',
  'performance', 'competitiveness', 'reform', 'assistance', 'growth',
  'effort', 'capacity', 'transparency', 'effectiveness',
  'progress', 'stability', 'protection', 'access',
  'implementation', 'sustainable', 'stuff', 'really',
  'etc', 'many', 'usually', 'most',
  'somehow', 'somewhat', 'somewhat like',
  'soon', 'a few', 'apparently', 'almost',
  'fairly', 'nearly', 'partially', 'predominantly',
  'presumably', 'rather', 'relative', 'seemingly',
  'sort of', 'kind of', 'a bit', 'a little',
  'maybe', 'some', 'partly', 'perhaps',
  'any', 'probably', 'a touch', 'sometimes',
  'mostly', 'possibly', 'might', 'a tad',
  'hardly', 'seem'
]);
