import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import { useTour } from '@reactour/tour';

export default function WelcomeModal(props: { showFlag: boolean; setShowFlag: Function }) {
  const { showFlag, setShowFlag } = props;

  const { setIsOpen } = useTour();

  const handleClose = () => setShowFlag(false);
  return (
    <Dialog
      open={showFlag}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { padding: 0 } }}
    >
      <div style={{ maxWidth: 555, maxHeight: 617 }}>
        <div style={{ maxHeight: 399, backgroundColor: '#C9DEFF' }}>
          <img
            src={require('../../assets/images/guide/lighthouse.svg')}
            alt="Welcome"
            style={{ display: 'block', maxWidth: '100%', maxHeight: '100%' }}
          />
        </div>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 20,
            textTransform: 'capitalize',
            color: '#232D35',
            paddingLeft: 48,
            paddingRight: 10,
            marginTop: 32,
            marginBottom: 10
          }}
        >
          Welcome !
        </div>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 14,
            color: '#495864',
            paddingLeft: 48,
            paddingRight: 10,
            marginBottom: 24
          }}
        >
          Welcome to Homer! Excited to simplify your writing? We thought so! Let’s go on a small tour of Homer.

        </div>
      </div>
      <DialogActions style={{ padding: 32 }}>
        <Button
          onClick={handleClose}
          style={{ backgroundColor: 'transparent' }}
          className="generic-light-button"
          variant="text"
          color="primary"
        >
          Skip guide
        </Button>
        <Box m="10px" />
        <Button
          onClick={() => {
            handleClose();
            setTimeout(() => {
              setIsOpen(true);
            }, 100);
          }}
          variant="contained"
          color="primary"
        >
          Get started
        </Button>
      </DialogActions>
    </Dialog>
  );
}
