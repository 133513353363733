import axios from 'axios';
import { MsgCantParseTokens } from '../../shared/constants/messages';
import { history } from '../../shared/helpers';

import { FreshTokens } from '../models/freshTokens';
import { TokensService } from './tokensService';

require('dotenv').config();

export class AuthService {
  tokensService = new TokensService();

  async signUp(user: { email: string; password: string; confirmPassword: string }): Promise<FreshTokens> {
    return await axios.post(`/auth/local/signup`, JSON.stringify(user)).then(res => res.data);
  }

  async signIn(user: { email: string; password: string }): Promise<FreshTokens> {
    return axios.post(`/auth/local/signin`, JSON.stringify(user)).then(res => res.data);
  }

  async sendForgotPasswordEmail(email: string): Promise<void> {
    return await axios.post(`/auth/local/forgot-password`, JSON.stringify(email)).then(res => res.data);
  }

  async sendNewPassword(data: { token: string; password: string; confirmPassword: string }): Promise<void> {
    return await axios.post(`/auth/local/verify-new-password`, JSON.stringify(data)).then(res => res.data);
  }

  async updatePassword(data: { oldPassword: string; newPassword: string }): Promise<void> {
    return await axios.post(`/auth/local/update-password`, JSON.stringify(data));
  }

  logout(): void {
    this.tokensService.removeRefreshToken();
    this.tokensService.removeAccessToken();
    this.tokensService.removeExpiration();
    history.push('/login');
  }

  getSignInRedirectUrl(provider: string): string {
    return `${process.env.REACT_APP_API_URL}/api/auth/${provider}/login`;
  }

  parseFreshTokens(tokens: FreshTokens): void {
    const { refreshToken, accessToken, expiresAt } = tokens;

    if (!refreshToken || !accessToken || !expiresAt) {
      throw new Error(MsgCantParseTokens);
    }

    this.tokensService.setRefreshToken(refreshToken);
    this.tokensService.setAccessToken(accessToken);
    this.tokensService.setExpiration(expiresAt);
  }
}
