import { EditorState } from 'draft-js';
import React, { useState } from 'react';
import './toolbar.scss';
import SimpleFormModal from './components/shared/SimpleFormModal';
import { StyleButton } from './ToolbarButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const BLOCK_TYPES = [
  { key: 'H1', label: 'H1', classes: 'style-button', style: 'header-one', type: 'block' },
  { key: 'H2', label: 'H2', classes: 'style-button', style: 'header-two', type: 'block' },
  { key: 'H3', label: 'H3', classes: 'style-button', style: 'header-three', type: 'block' },
  { key: 'bold', label: '', classes: 'style-button bold', style: 'BOLD', type: 'inline' },
  { key: 'italic', label: '', classes: 'style-button italic', style: 'ITALIC', type: 'inline' },
  { key: 'underline', label: '', classes: 'style-button underline', style: 'UNDERLINE', type: 'inline' },
  { key: 'ul', label: '', classes: 'style-button ul', style: 'unordered-list-item', type: 'block' },
  { key: 'li', label: '', classes: 'style-button li', style: 'ordered-list-item', type: 'block' }
];

export function Toolbar(props: { editorState: EditorState; onToggle: Function; documentHash: string }) {
  const { editorState, onToggle, documentHash } = props;
  const [sharePopupShow, setSharePopupShow] = useState<boolean>(false);
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <>
      <div className="toolbar">
        {BLOCK_TYPES.map(type => (
          <StyleButton
            key={type.key}
            active={type.type === 'block' ? type.style === blockType : currentStyle.has(type.style)}
            label={type.label}
            classes={type.classes}
            type={type.type}
            onToggle={onToggle}
            style={type.style}
          />
        ))}
        <button
          onClick={() => {
            setSharePopupShow(true);
          }}
          className="tool-share-button"
        >
          <img src={require('./assets/images/blue-share.svg')} />
        </button>
      </div>
      <SimpleFormModal
        showFlag={sharePopupShow}
        setShowFlag={setSharePopupShow}
        title="Share the document"
        description="Anyone with this link can view the document."
        inputElement={
          <input
            type="text"
            className="new-doc-input"
            value={`${process.env.REACT_APP_CLI_URL}/shared/${documentHash}`}
          />
        }
        customOkElement={
          <CopyToClipboard
            text={`${process.env.REACT_APP_CLI_URL}/shared/${documentHash}`}
            onCopy={() => {
              setSharePopupShow(false);
            }}
          >
            <button className="generic-action-button">Copy to clipboard</button>
          </CopyToClipboard>
        }
      />
    </>
  );
}
