import React, { Component } from 'react';

import { EMAIL_REGEX } from '../../shared/constants';
import { AuthService } from '../services/authService';
import { FormErrorsHandlerService } from '../../shared/services';
import { ErrorData } from '../../shared';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './generic.scss';
import { getErrorContent } from '../../shared/functions/process-error';
import { MsgServerErrorCheckConnection } from '../../shared/constants/messages';

export default class ForgotPassword extends Component<any, any> {
  authService = new AuthService();
  formErrorsHandlerService = new FormErrorsHandlerService();

  constructor(prop: any) {
    super(prop);

    this.state = {
      user: {
        email: ''
      },
      touched: {
        email: false
      },
      errors: {
        required: {
          email: false
        },
        valid: {
          email: false
        },
        messages: {
          required: {
            email: 'Email is required'
          },
          valid: {
            email: 'Email is not valid'
          }
        },
        serverError: ''
      },
      processing: false,
      sendPopupShow: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event: any): void {
    const { name, value } = event.target;
    const { user } = this.state;
    const errors = {
      required: {
        ...this.state.errors.required,
        [name]: false
      },
      user: {
        [name]: value
      }
    };

    this.setState({
      user: {
        ...user,
        [name]: value
      },
      errors: { ...this.state.errors, ...errors, serverError: '' }
    });
  }

  handleBlur(event: any): void {
    const field = event.target.name;
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });

    this.validateField(event);
  }

  handleSubmit(event: any): void {
    event.preventDefault();
    const { user } = this.state;

    if (user && user.email) {
      this.setState({ processing: true });
      this.authService
        .sendForgotPasswordEmail(user)
        .then(() => {
          this.setState({ sendPopupShow: true });
        })
        .catch((error: { response: { data: ErrorData } } | ErrorData) => {
          this.setState({
            errors: {
              ...this.state.errors,
              serverError: getErrorContent(error, MsgServerErrorCheckConnection)
            }
          });
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    }
  }

  hasError(field: string): boolean {
    return this.formErrorsHandlerService.hasError(this.state, field);
  }

  isFormInvalid(): boolean {
    return !this.state.user.email;
  }

  displayError(field: string): string {
    return this.formErrorsHandlerService.displayError(this.state, field);
  }

  private validateField(event: any): void {
    const { value, name } = event.target;

    if (value.length === 0) {
      const errors = {
        required: {
          ...this.state.errors.required,
          [name]: true
        }
      };

      this.setState({
        errors: { ...this.state.errors, ...errors }
      });
      return;
    }

    if (name === 'email') {
      this.validateEmail(value);
    }
  }

  private validateEmail(email: string): void {
    const emailIsValid = EMAIL_REGEX.test(email);
    const errors = {
      valid: {
        ...this.state.errors.valid,
        email: emailIsValid
      }
    };

    this.setState({
      errors: { ...this.state.errors, ...errors }
    });
  }

  render(): JSX.Element {
    const { user } = this.state;

    return (
      <>
        <div className="container-fluid w-100 h-100 auth-container bg-white">
          <div className="row d-flex h-100 justify-content-center">
            <div className="h-100 bg-white">
              <div className="homer-header">
                <span className="homer-title">
                  <a href="http://www.homerwritingapp.com">Homer</a>
                </span>
              </div>

              <div className="heading-text homer-heading-desc">
                <h1>Reset password</h1>
                <p>Enter your email address to get a reset password link:</p>
              </div>

              <form className="mt-4" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    className={`form-control ${this.hasError('email') ? 'error' : ''}`}
                    id="email"
                    placeholder="Email"
                    name="email"
                    value={user.email}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  <span className={this.hasError('email') ? 'error-message__visible' : 'error-message'}>
                    {this.displayError('email')}
                  </span>
                  <span className={this.state.errors.serverError ? 'error-message__visible' : 'error-message'}>
                    {this.state.errors.serverError}
                  </span>
                </div>

                <button
                  className="btn btn-primary btn-default btn-block mt-4"
                  disabled={this.state.processing || this.isFormInvalid()}
                >
                  {this.state.processing ? 'Wait please...' : 'Send me reset link'}
                </button>
              </form>
            </div>
          </div>
        </div>
        <Modal show={this.state.sendPopupShow}>
          <Modal.Body>
            <h5>Recover password</h5>
            <div>Please check your email.</div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="generic-action-button"
              onClick={() => {
                this.setState({ sendPopupShow: false });
                this.props.history.push('/');
              }}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
