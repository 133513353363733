/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/camelcase, @typescript-eslint/ban-ts-ignore, no-debugger, no-prototype-builtins */

export class LocalStorageService {
  saveToStorage(data: { [key: string]: string }): void {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        localStorage.setItem(key, data[key]);
      }
    }
  }

  getFromStorage(key: string): string | null {
    return localStorage.getItem(key) || null;
  }

  removeFromStorage(key: string | string[]): void {
    if (Array.isArray(key)) {
      key.forEach(k => {
        localStorage.removeItem(k);
      });
    } else {
      localStorage.removeItem(key);
    }
  }
}
