/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthService } from '../services/authService';
import './registration.scss';
import { ErrorData } from '../../shared';
import { getErrorContent } from '../../shared/functions/process-error';
import { MsgEnterEmail, MsgEnterPassword, MsgServerErrorCheckConnection } from '../../shared/constants/messages';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { ToastContainer } from 'react-toastify';
import { goToastError } from '../../components/shared/Toasts';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const authService = new AuthService();

const Schema = Yup.object().shape({
  email: Yup.string()
    .required(MsgEnterEmail)
    .email(MsgEnterEmail),
  password: Yup.string()
    .min(8, MsgEnterPassword)
    .required(MsgEnterPassword)
});

export default function Registration() {
  const [emailFieldFocused, setEmailFieldFocused] = useState<boolean>(false);
  const emailField = useRef<any>(null);
  const [passwordFieldFocused, setPasswordFieldFocused] = useState<boolean>(false);
  const passwordField = useRef<any>(null);
  const [passwordFieldType, setPasswordFieldType] = useState<string>('password');
  const history = useHistory();

  return (
    <>
      <div className="registration-continer">
        <div className="registration-content">
          <span className="registration-title">
            <a href="http://www.homerwritingapp.com">Homer</a>
          </span>
        </div>
        <div className="registration-desc" style={{ textAlign: 'center' }}>
          <h1>Try Homer free for 14 days</h1>
          <div className="registration-desc-add-info">No credit card required. Cancel anytime.</div>
        </div>

        <div style={{ width: 399, margin: 'auto', marginTop: 64 }}>
          <div>
            <a
              onClick={() => {
                localStorage.setItem('homer_guide', 'true');
                window.location.href = authService.getSignInRedirectUrl('google');
              }}
              className="btn btn-primary btn-google btn-block"
              style={{ textAlign: 'center', position: 'relative' }}
            >
              <img
                src={require('../../assets/images/google.svg')}
                alt="Google"
                className="bg-white rounded"
                style={{ position: 'absolute', top: 16, left: 16 }}
              />
              Sign up with Google
            </a>
          </div>

          <p className="mt-4 mb-4 horizontal-divider">
            <span>or</span>
          </p>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            isInitialValid={false}
            validationSchema={Schema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              authService
                .signUp({ ...values, confirmPassword: values.password })
                .then(() => {
                  localStorage.setItem('homer_guide', 'true');
                  history.push('/');
                })
                .catch((error: { response: { data: ErrorData } } | ErrorData) => {
                  goToastError(getErrorContent(error, MsgServerErrorCheckConnection));
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ errors, values, touched, isSubmitting, handleSubmit, isValid }) => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  if (!isValid) {
                    if (errors.password && passwordField && passwordField.current) {
                      passwordField.current.focus();
                    } else if (emailField.current) {
                      emailField.current.focus();
                    }
                  }
                  handleSubmit(e);
                }}
              >
                <div className="form-group custom-if-tooltip mb-2">
                  {emailFieldFocused && errors.email && touched.email && (
                    <span className="custom-if-tooltiptext">{errors.email}</span>
                  )}
                  <Field
                    type="text"
                    innerRef={emailField}
                    className={`form-control custom-if-tooltip ${errors.email && touched.email ? 'error' : ''}`}
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onFocus={() => {
                      setEmailFieldFocused(true);
                    }}
                    onBlur={() => {
                      setEmailFieldFocused(false);
                    }}
                  />
                </div>

                <div className="form-group custom-if-tooltip mb-2">
                  {passwordFieldFocused && errors.password && touched.password && (
                    <span className="custom-if-tooltiptext">{errors.password}</span>
                  )}
                  <Field
                    type={passwordFieldType}
                    innerRef={passwordField}
                    className={`form-control ${errors.password && touched.password ? 'error' : ''}`}
                    placeholder="Password (minimum 8 characters)"
                    name="password"
                    value={values.password}
                    onBlur={() => {
                      setPasswordFieldFocused(false);
                    }}
                    onFocus={() => {
                      setPasswordFieldFocused(true);
                    }}
                  />
                  {passwordFieldType === 'password' ? (
                    <VisibilityIcon
                      onClick={() => {
                        setPasswordFieldType('text');
                      }}
                      className="eye-pwd"
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => {
                        setPasswordFieldType('password');
                      }}
                      className="eye-pwd"
                    />
                  )}
                </div>

                <button className="btn btn-primary btn-default btn-block mt-4" disabled={isSubmitting}>
                  {isSubmitting ? 'Please wait...' : 'Create my account'}
                </button>
              </Form>
            )}
          </Formik>

          <p className="have-account" style={{ textAlign: 'center' }}>
            Already have an account?{' '}
            <Link to={`/login`} className="underline">
              Sign in
            </Link>
          </p>
          <ToastContainer closeButton={false} />
        </div>
      </div>
    </>
  );
}
