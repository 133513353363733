import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { AuthService, TokensService } from './auth';

require('dotenv').config();

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
axios.defaults.headers['Content-Type'] = 'application/json';

const tokensService = new TokensService();
const authService = new AuthService();

async function refreshAccessToken() {
  const token = localStorage.getItem('refresh_token');
  return await axios.get(`/account/refreshToken?token=${token}`).then(res => res.data);
}

axios.interceptors.request.use(
  request => {
    if (request && request.url && request.url.indexOf('/share/') > 0) {
      return request;
    }
    const currentAccessToken = tokensService.getAccessToken();
    request.headers = { ...request.headers, Authorization: 'Bearer ' + currentAccessToken };

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    const { url } = response.config;
    if (url && (url.includes('signup') || url.includes('signin'))) {
      authService.parseFreshTokens(response.data);
    }

    return response;
  },
  async error => {
    const originalRequest = error.config;
    const { status } = error.response;

    if (status === 401 || status === 403) {
      // authService.logout();
      // todo: fix it!
      const tokenData = await refreshAccessToken();
      localStorage.setItem('expires_at', tokenData.expiresAt);
      localStorage.setItem('access_token', tokenData.accessToken);
      localStorage.setItem('refresh_token', tokenData.refreshToken);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenData.accessToken;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
