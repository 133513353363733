import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

export default function SimpleFormModal(props: {
  showFlag: boolean;
  setShowFlag: Function;
  title: string;
  inputElement: JSX.Element;
  okTitle?: string;
  okAction?: Function;
  okDisabled?: boolean;
  customOkElement?: JSX.Element;
  description?: string | JSX.Element;
}) {
  const {
    showFlag,
    setShowFlag,
    title,
    description,
    inputElement,
    okAction,
    okTitle,
    okDisabled,
    customOkElement
  } = props;
  const handleClose = () => setShowFlag(false);
  return (
    <Dialog open={showFlag} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <span
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 20,
            textTransform: 'capitalize',
            color: '#232D35'
          }}
        >
          {title}
        </span>
      </DialogTitle>
      <DialogContent style={{ minWidth: 500 }}>
        <DialogContentText>{inputElement}</DialogContentText>
        {description && <DialogContentText>{description}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ backgroundColor: 'transparent' }}
          className="generic-light-button"
          variant="text"
          color="primary"
        >
          Cancel
        </Button>
        <Box m="10px" />
        {customOkElement ? (
          customOkElement
        ) : (
          <Button
            onClick={() => {
              handleClose();
              if (!okAction) {
                throw Error(`Invalid component using: okAction should be defined`);
              }
              okAction();
            }}
            disabled={okDisabled || false}
            variant="contained"
            color="primary"
          >
            {okTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
