import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import axios from 'axios';

export const FreePlanActive = 15;

export interface AccountDesc {
  email: string;
  stripeCustomerId: string;
  createdTime: string;
  daysRest: number;
  daysLabel: string;
  isRegisteredByGoogleOrLinkedIn: boolean;
  features?: string[];
}

export interface PriceDesc {
  id: string;
  amount: number;
  currency: string;
  interval: string;
}

export interface SubscriptionDesc {
  id: string;
  plan: PriceDesc;
  created: number;
  current_period_end: number;
  cancel_at_period_end: boolean;
}

export enum SubscriptionType {
  Undefined,
  Free,
  Annual,
  Monthly
}

export interface UIDocumentTemplate {
  id: number;
  name: string;
  label: string;
  content?: string;
}

function getDaysDiff(date1: Date, date2: Date) {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

export interface GlobalContextDesc {
  account: AccountDesc | null;
  setAccount: Function;
  annualPrice: PriceDesc | null;
  setAnnualPrice: Function;
  monthlyPrice: PriceDesc | null;
  setMonthlyPrice: Function;
  subscription: SubscriptionDesc | null | undefined;
  setSubscription: Function;
  isFreeSubscription: boolean | null;
  setIsFreeSubscription: Function;
  currentSubscriptionType: SubscriptionType;
  setCurrentSubscriptionType: Function;
  lostConnectionFlag: boolean;
  setLostConnectionFlag: Function;
  commonWords: Set<string> | null;
  setCommonWords: Function;
  templates: UIDocumentTemplate[] | null;
  setTemplates: Function;
}

export const GlobalContext = createContext<GlobalContextDesc>({
  account: null,
  setAccount: () => null,
  annualPrice: null,
  setAnnualPrice: () => null,
  monthlyPrice: null,
  setMonthlyPrice: () => null,
  subscription: undefined,
  setSubscription: () => null,
  isFreeSubscription: null,
  setIsFreeSubscription: () => null,
  currentSubscriptionType: SubscriptionType.Undefined,
  setCurrentSubscriptionType: () => null,
  lostConnectionFlag: false,
  setLostConnectionFlag: () => null,
  commonWords: null,
  setCommonWords: () => null,
  templates: null,
  setTemplates: () => null,
});

export function GlobalProvider({ children }: { children: ReactNode }) {
  const [account, setAccount] = useState<AccountDesc | null>(null);
  const [subscription, setSubscription] = useState<SubscriptionDesc | null | undefined>();
  const [isFreeSubscription, setIsFreeSubscription] = useState<boolean | null>(null);
  const [annualPrice, setAnnualPrice] = useState<PriceDesc | null>(null);
  const [monthlyPrice, setMonthlyPrice] = useState<PriceDesc | null>(null);
  const [currentSubscriptionType, setCurrentSubscriptionType] = useState<SubscriptionType>(SubscriptionType.Undefined);
  const [lostConnectionFlag, setLostConnectionFlag] = useState<boolean>(false);
  const [commonWords, setCommonWords] = useState<Set<string> | null>(null);
  const [templates, setTemplates] = useState<UIDocumentTemplate[] | null>(null);
  const getAccount = async () => axios.get(`/account`);
  const getSubscriptions = async () => axios.get(`/payment/subscriptions`);
  const getPrices = async () => axios.get(`/payment/prices`);
  const getCommonWords = async () => axios.get(`/settings/common-words`);
  const getDocumentTemplates = async () => axios.get(`/document/templates`);
  const isActiveSubscriptionAnnual = () => subscription && subscription.plan.id === annualPrice?.id;
  const isActiveSubscriptionMonthly = () => subscription && subscription.plan.id === monthlyPrice?.id;
  const fillAccount = (res: any) => {
    const daysDiff = getDaysDiff(new Date(res.data.createdAt), new Date());
    const daysRest = FreePlanActive - daysDiff;
    const daysLabel = daysRest === 1 ? 'day' : 'days';
    setAccount({
      email: res.data.email,
      createdTime: res.data.createdAt,
      daysRest,
      daysLabel,
      isRegisteredByGoogleOrLinkedIn: res.data.isRegisteredByGoogleOrLinkedIn,
      stripeCustomerId: res.data.stripeCustomerId,
      features: res.data.features
    });
  };
  const fillCommonWords = () => {
    if (commonWords === null) {
      getCommonWords()
        .then(({ data: commonWordsStr }) => {
          setCommonWords(new Set(commonWordsStr));
        })
        // tslint:disable-next-line: no-unbound-method
        .catch(console.error);
    }
  };
  const fillDocumentTemplates = () => {
    if (templates === null) {
      getDocumentTemplates()
        .then(({ data }) => {
          setTemplates(data);
        })
        // tslint:disable-next-line: no-unbound-method
        .catch(console.error);
    }
  };
  useEffect(() => {
    if (subscription === undefined) {
      setCurrentSubscriptionType(SubscriptionType.Undefined);
    } else if (subscription === null) {
      setCurrentSubscriptionType(SubscriptionType.Free);
    } else if (isActiveSubscriptionAnnual()) {
      setCurrentSubscriptionType(SubscriptionType.Annual);
    } else if (isActiveSubscriptionMonthly()) {
      setCurrentSubscriptionType(SubscriptionType.Monthly);
    }
    setIsFreeSubscription(subscription === null);
  }, [subscription]);

  useEffect(() => {
    let accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      fillCommonWords();
      fillDocumentTemplates();
      getPrices()
        .then((plans: { data: { data: PriceDesc[] } }) => {
          for (const plan of plans.data.data) {
            if (plan.interval === 'month') {
              setMonthlyPrice({
                id: plan.id,
                amount: plan.amount / 100,
                currency: plan.currency,
                interval: plan.interval
              });
            }
            if (plan.interval === 'year') {
              setAnnualPrice({
                id: plan.id,
                amount: plan.amount / 100,
                currency: plan.currency,
                interval: plan.interval
              });
            }
          }
        })
        .then(getSubscriptions)
        .then((res: { data: { data: SubscriptionDesc[] } }) => {
          setIsFreeSubscription(res.data.data.length <= 0);
          setSubscription(res.data.data.length <= 0 ? null : res.data.data[0]);
        })
        .then(getAccount)
        .then(fillAccount)
        .catch(err => {
          console.log(err);
          // alert(`Can't get current plan or account`);
        });
    }
    const intervalId = setInterval(() => {
      accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        getAccount()
          .then(fillAccount)
          .catch(() => null);
      }
    }, 1000 * 60 * 60 * 6);
    // }, 1000 * 10);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const contextValue = {
    account,
    setAccount,
    annualPrice,
    setAnnualPrice,
    monthlyPrice,
    setMonthlyPrice,
    subscription,
    setSubscription,
    isFreeSubscription,
    setIsFreeSubscription,
    currentSubscriptionType,
    setCurrentSubscriptionType,
    lostConnectionFlag,
    setLostConnectionFlag,
    commonWords,
    setCommonWords,
    templates,
    setTemplates,
  };

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
}

export const useGlobalState = () => useContext(GlobalContext);
