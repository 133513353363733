export class FormErrorsHandlerService {
  hasError(state: any, field: string): boolean {
    return (state.errors.required[field] || !state.errors.valid[field]) && state.touched[field];
  }

  isFormInvalid(state: any): boolean {
    const {errors} = state;
    const {required, valid} = errors;
    const isSomeFieldRequired = Object.keys(required).some(error => required[error]);
    const isSomeFieldInvalid = Object.keys(valid).some(error => !valid[error]);

    return isSomeFieldInvalid || isSomeFieldRequired;
  }

  displayError(state: any, field: string): string {
    const {required, valid} = state.errors;
    const {errors} = state;

    if (required[field]) {
      return errors.messages.required[field];
    }

    if (!valid[field]) {
      return errors.messages.valid[field];
    }

    return '';
  }
}
