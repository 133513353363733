import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { TokensService } from '../../auth/services/tokensService';

export const PrivateRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const tokensService = new TokensService();
  const currentAccessToken = tokensService.getAccessToken();

  return (
    <Route
      {...rest}
      render={props =>
        currentAccessToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};
