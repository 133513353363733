import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  message: string | undefined;
  setMessage: Function;
  okAcion: Function;
  adornment?: JSX.Element;
}

export default function PromptModal(props: Props) {
  const { message, setMessage, okAcion, adornment } = props;
  const handleClose = () => setMessage(undefined);
  return (
    <Dialog open={!!message} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        <DialogContentText>
          {adornment && <span>{adornment}</span>}
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ backgroundColor: 'transparent' }}
          className="generic-light-button"
          variant="text"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleClose();
            okAcion();
          }}
          variant="contained"
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
