import React, { Component } from 'react';
import * as queryString from 'querystring';

import { PASSWORD_REGEX } from '../../shared/constants';
import { checkForNumber, checkForSymbols } from '../../shared/functions';
import { AuthService } from '../services/authService';
import defaultImageUrl from '../../assets/images/password-default.svg';
import successImageUrl from '../../assets/images/password-success.svg';
import { FormErrorsHandlerService } from '../../shared/services';
import { ErrorData } from '../../shared';
import { getErrorContent } from '../../shared/functions/process-error';
import {
  MsgConfirmPasswordNotValid,
  MsgConfirmPasswordRequired,
  MsgPasswordNotValid,
  MsgPasswordRequired,
  MsgServerErrorCheckConnection
} from '../../shared/constants/messages';

export default class RecoveryPassword extends Component<any, any> {
  authService = new AuthService();
  formErrorsHandlerService = new FormErrorsHandlerService();

  constructor(props: any) {
    super(props);

    this.state = {
      user: {
        password: '',
        confirmPassword: ''
      },
      touched: {
        password: false,
        confirmPassword: false
      },
      errors: {
        required: {
          password: false,
          confirmPassword: false
        },
        valid: {
          password: false,
          confirmPassword: false
        },
        messages: {
          required: {
            password: MsgPasswordRequired,
            confirmPassword: MsgConfirmPasswordRequired
          },
          valid: {
            password: MsgPasswordNotValid,
            confirmPassword: MsgConfirmPasswordNotValid
          }
        },
        serverError: ''
      },
      passwordRequirements: {
        firstRuleImage: {
          url: defaultImageUrl,
          isValid: false
        },
        secondRuleImage: {
          url: defaultImageUrl,
          isValid: false
        },
        thirdRuleImage: {
          url: defaultImageUrl,
          isValid: false
        }
      },
      processing: false,
      sendPopupShow: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleKeyUp(event: any): void {
    const { name, value } = event.target;

    switch (name) {
      case 'password':
        this.validatePassword(value);
        break;
      case 'confirmPassword':
        this.validateConfirmPassword(value);
        break;
    }
  }

  handleChange(event: any): void {
    const { name, value } = event.target;
    const { user } = this.state;
    const errors = {
      required: {
        ...this.state.errors.required,
        [name]: false
      },
      user: {
        [name]: value
      }
    };

    this.setState({
      user: {
        ...user,
        [name]: value
      },
      errors: { ...this.state.errors, ...errors, serverError: '' }
    });
  }

  handleBlur(event: any): void {
    const field = event.target.name;
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });

    this.validateField(event);
  }

  handleSubmit(event: any): void {
    event.preventDefault();
    const { user } = this.state;
    const queryObj = queryString.parse(this.props.location.search.substring(1));

    if (user && user.password && user.confirmPassword) {
      this.setState({ processing: true });
      this.authService
        .sendNewPassword({ ...user, token: queryObj.token.toString() })
        .then(() => {
          this.props.history.push('/');
        })
        .catch((error: { response: { data: ErrorData } } | ErrorData) => {
          this.setState({
            errors: {
              ...this.state.errors,
              serverError: getErrorContent(error, MsgServerErrorCheckConnection)
            }
          });
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    }
  }

  validateField(event: any): void {
    const { value, name } = event.target;

    if (value.length === 0) {
      const errors = {
        required: {
          ...this.state.errors.required,
          [name]: true
        }
      };

      this.setState({
        errors: { ...this.state.errors, ...errors }
      });
      return;
    }

    switch (name) {
      case 'password':
        this.validatePassword(value);
        break;
      case 'confirmPassword':
        this.validateConfirmPassword(value);
        break;
    }
  }

  hasError(field: string): boolean {
    return this.formErrorsHandlerService.hasError(this.state, field);
  }

  isFormInvalid(): boolean {
    return this.formErrorsHandlerService.isFormInvalid(this.state);
  }

  displayError(field: string): string {
    return this.formErrorsHandlerService.displayError(this.state, field);
  }

  private validateConfirmPassword(confirmPassword: string): void {
    const { user } = this.state;
    const confirmPasswordIsValid = user.password === confirmPassword;
    const errors = {
      valid: {
        ...this.state.errors.valid,
        confirmPassword: confirmPasswordIsValid
      }
    };

    this.setState({
      errors: { ...this.state.errors, ...errors }
    });
  }

  private validatePassword(password: string): void {
    let firstRuleImage, secondRuleImage, thirdRuleImage;
    const passwordIsValid = PASSWORD_REGEX.test(password);
    const errors = {
      valid: {
        ...this.state.errors.valid,
        password: passwordIsValid
      }
    };

    this.setState({
      errors: { ...this.state.errors, ...errors }
    });

    if (password.length >= 8) {
      firstRuleImage = this.updateRuleState(successImageUrl, true);
    } else if (password.length < 8) {
      firstRuleImage = this.updateRuleState(defaultImageUrl, false);
    }

    if (checkForNumber(password)) {
      secondRuleImage = this.updateRuleState(successImageUrl, true);
    } else if (!checkForNumber(password)) {
      secondRuleImage = this.updateRuleState(defaultImageUrl, false);
    }

    if (checkForSymbols(password)) {
      thirdRuleImage = this.updateRuleState(successImageUrl, true);
    } else if (!checkForSymbols(password)) {
      thirdRuleImage = this.updateRuleState(defaultImageUrl, false);
    }

    this.setState({
      passwordRequirements: {
        ...this.state.passwordRequirements,
        firstRuleImage,
        secondRuleImage,
        thirdRuleImage
      }
    });
  }

  private updateRuleState(url: string, isValid: boolean): {} {
    return { url, isValid };
  }

  render(): JSX.Element {
    const { user, passwordRequirements } = this.state;

    return (
      <>
        <div className="container-fluid w-100 h-100 auth-container bg-white">
          <div className="row d-flex h-100 justify-content-center">
            <div className="h-100 bg-white">
              <div className="homer-header">
                <span className="homer-title">
                  <a href="http://www.homerwritingapp.com">Homer</a>
                </span>
              </div>

              <div className="heading-text">
                <h1>Recovery password</h1>
                <p>Please enter your new password:</p>
              </div>

              <form className="mt-4" onSubmit={this.handleSubmit}>
                <div className="form-group mb-2">
                  <input
                    type="password"
                    className={`form-control ${this.hasError('password') ? 'error' : ''}`}
                    id="password"
                    placeholder="Password"
                    name="password"
                    value={user.password}
                    onChange={this.handleChange}
                    onKeyUp={this.handleKeyUp}
                    onBlur={this.handleBlur}
                    onFocus={this.handleBlur}
                  />
                  <span className={this.hasError('password') ? 'error-message__visible' : 'error-message'}>
                    {this.displayError('password')}
                  </span>
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    className={`form-control ${this.hasError('confirmPassword') ? 'error' : ''}`}
                    id="confirmPassword"
                    placeholder="Confirm password"
                    name="confirmPassword"
                    value={user.confirmPassword}
                    onChange={this.handleChange}
                    onKeyUp={this.handleKeyUp}
                    onBlur={this.handleBlur}
                    onFocus={this.handleBlur}
                  />
                  <span className={this.hasError('confirmPassword') ? 'error-message__visible' : 'error-message'}>
                    {this.displayError('confirmPassword')}
                  </span>
                  <span className={this.state.errors.serverError ? 'error-message__visible' : 'error-message'}>
                    {this.state.errors.serverError}
                  </span>
                </div>

                {(!passwordRequirements.firstRuleImage.isValid ||
                  !passwordRequirements.secondRuleImage.isValid ||
                  !passwordRequirements.thirdRuleImage.isValid) &&
                  user.password.length > 0 && (
                    <div className="d-flex flex-column password-req">
                      <span className="title mb-2">Password Requirements:</span>
                      <div className="d-flex mb-1">
                        <img
                          className="mr-2"
                          src={passwordRequirements.firstRuleImage.url}
                          alt="Password strength image"
                        />
                        <span>8 or more characters</span>
                      </div>
                      <div className="d-flex mb-1">
                        <img
                          className="mr-2"
                          src={passwordRequirements.secondRuleImage.url}
                          alt="Password strength image"
                        />
                        <span>Contains a number</span>
                      </div>
                      <div className="d-flex">
                        <img
                          className="mr-2"
                          src={passwordRequirements.thirdRuleImage.url}
                          alt="Password strength image"
                        />
                        <span>Contains a symbol</span>
                      </div>
                    </div>
                  )}

                <button
                  className="btn btn-primary btn-default btn-block mt-4"
                  disabled={this.state.processing || this.isFormInvalid()}
                >
                  {this.state.processing ? 'Wait please...' : 'Set new password'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
