import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

export default function CancelPlanModal(props: {
  show: boolean;
  setShow: Function;
  okAcion: Function;
  tillDate: string;
}) {
  const { show, setShow, okAcion, tillDate } = props;
  const handleClose = () => {
    setShow(false);
  };
  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogContent style={{ minWidth: 300 }}>
        <DialogContentText>
          We’re sorry to see you go!
          <br />
          <br />
          If you’ve a feature request, or if something is bothering you in the app, please write to us at{' '}
          <a href="mailto:hello@homerwritingapp.com">hello@homerwritingapp.com</a>. We’ll try our best to help you. Your
          subscription is paid until {tillDate}. If you want to proceed with cancellation, please click the "Cancel
          Subscription" button below.
          <br />
          <br />
          Please note that when your account is cancelled, you’ll not be able to create or analyse documents. If you
          don’t want to lose your documents, please download them before {tillDate}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ backgroundColor: 'transparent' }}
          className="generic-light-button"
          variant="text"
          color="primary"
        >
          Close
        </Button>
        <Button
          onClick={() => {
            handleClose();
            okAcion();
          }}
          variant="contained"
          color="primary"
        >
          Cancel Subscription
        </Button>
      </DialogActions>
    </Dialog>
  );
}
