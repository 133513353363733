import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { EMAIL_REGEX } from '../../shared/constants';
import './generic.scss';
import './login.scss';
import { AuthService } from '../services/authService';
import { ErrorData } from '../../shared/models';
import { FormErrorsHandlerService } from '../../shared/services';
import { getErrorContent } from '../../shared/functions/process-error';
import {
  MsgEmailNotValid,
  MsgEmailRequired,
  MsgPasswordNotValid,
  MsgPasswordRequired,
  MsgServerErrorCheckConnection
} from '../../shared/constants/messages';

export class Login extends Component<any, any> {
  authService = new AuthService();
  formErrorsHandlerService = new FormErrorsHandlerService();

  constructor(prop: any) {
    super(prop);

    this.state = {
      user: {
        email: '',
        password: ''
      },
      touched: {
        email: false,
        password: false
      },
      errors: {
        required: {
          email: false,
          password: false
        },
        valid: {
          email: false,
          password: true
        },
        messages: {
          required: {
            email: MsgEmailRequired,
            password: MsgPasswordRequired
          },
          valid: {
            email: MsgEmailNotValid,
            password: MsgPasswordNotValid
          }
        },
        serverError: ''
      },
      processing: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event: any): void {
    const { name, value } = event.target;
    const { user } = this.state;
    const errors = {
      required: {
        ...this.state.errors.required,
        [name]: false
      },
      user: {
        [name]: value
      }
    };

    this.setState({
      user: {
        ...user,
        [name]: value
      },
      errors: { ...this.state.errors, ...errors, serverError: '' }
    });
  }

  handleBlur(event: any): void {
    const field = event.target.name;
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });

    this.validateField(event);
  }

  handleSubmit(event: any): void {
    event.preventDefault();
    const { user } = this.state;
    this.setState({ processing: true });

    if (user && user.email && user.password) {
      this.authService
        .signIn(user)
        .then(() => {
          this.props.history.push('/');
          window.location.reload();
        })
        .catch((error: { response: { data: ErrorData } } | ErrorData) => {
          this.setState({
            errors: {
              ...this.state.errors,
              serverError: getErrorContent(error, MsgServerErrorCheckConnection)
            }
          });
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    }
  }

  redirectToSignInWithProvider(provider: string): void {
    window.location.href = this.authService.getSignInRedirectUrl(provider);
  }

  hasError(field: string): boolean {
    return this.formErrorsHandlerService.hasError(this.state, field);
  }

  isFormInvalid(): boolean {
    return this.formErrorsHandlerService.isFormInvalid(this.state);
  }

  displayError(field: string): string {
    return this.formErrorsHandlerService.displayError(this.state, field);
  }

  private validateField(event: any): void {
    const { value, name } = event.target;

    if (value.length === 0) {
      const errors = {
        required: {
          ...this.state.errors.required,
          [name]: true
        }
      };

      this.setState({
        errors: { ...this.state.errors, ...errors }
      });
      return;
    }

    if (name === 'email') {
      this.validateEmail(value);
    }
  }

  private validateEmail(email: string): void {
    const emailIsValid = EMAIL_REGEX.test(email);
    const errors = {
      valid: {
        ...this.state.errors.valid,
        email: emailIsValid
      }
    };

    this.setState({
      errors: { ...this.state.errors, ...errors }
    });
  }

  render(): JSX.Element {
    const { user } = this.state;

    return (
      <>
        <div className="container-fluid w-100 h-100 auth-container bg-white">
          <div className="row d-flex h-100 justify-content-center">
            <div className="h-100 bg-white">
              <div className="homer-header">
                <span className="homer-title">
                  <a href="http://www.homerwritingapp.com">Homer</a>
                </span>
              </div>

              <div className="heading-text homer-heading-desc">
                <h1>Sign in</h1>
              </div>

              <div className="google-wrapper">
                <a
                  onClick={() => this.redirectToSignInWithProvider('google')}
                  className="btn btn-primary btn-google btn-block"
                >
                  <img
                    src={require('../../assets/images/google.svg')}
                    loading="lazy"
                    alt="Google"
                    className="text-right bg-white p-1 rounded float-left"
                  />
                  Sign in with Google
                </a>
              </div>

              <p className="horizontal-divider" style={{ marginTop: 10, marginBottom: 10 }}>
                <span>or</span>
              </p>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    className={`form-control ${this.hasError('email') ? 'error' : ''}`}
                    id="email"
                    placeholder="Email"
                    name="email"
                    value={user.email}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  <span className={this.hasError('email') ? 'error-message__visible' : 'error-message'}>
                    {this.displayError('email')}
                  </span>
                  {this.state.errors.serverError === 'Invalid email' && (
                    <span className="error-message__visible">{this.state.errors.serverError}</span>
                  )}
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    className={`form-control ${this.hasError('password') ? 'error' : ''}`}
                    id="password"
                    placeholder="Password"
                    name="password"
                    value={user.password}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  <span className={this.hasError('password') ? 'error-message__visible' : 'error-message'}>
                    {this.displayError('password')}
                  </span>
                  {this.state.errors.serverError === 'Invalid password' && (
                    <span className="error-message__visible">{this.state.errors.serverError}</span>
                  )}
                </div>

                <Link to={`/reset-password`}>Forgot password?</Link>

                <button
                  className="btn btn-primary btn-default btn-block mt-2"
                  disabled={this.state.errors.processing || this.isFormInvalid()}
                >
                  {this.state.errors.processing ? 'Wait please...' : 'Sign in'}
                </button>
                {this.state.errors.serverError &&
                  this.state.errors.serverError !== 'Invalid password' &&
                  this.state.errors.serverError !== 'Invalid email' && (
                    <span className="error-message__visible">{this.state.errors.serverError}</span>
                  )}
              </form>

              <p className="have-account">
                Don't have an account?{' '}
                <Link to={`/registration`} className="underline">
                  Create a free account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
