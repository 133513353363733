import React, { useEffect, useRef, useState } from 'react';
import './topMenu.scss';
import { useHistory } from 'react-router-dom';
import { AuthService } from '../../auth';
import { SubscriptionType, useGlobalState } from './GlobalContext';
import { ProfileTabs } from '../profile/Profile';
import { Button, withStyles } from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTour } from '@reactour/tour';

const StyledMenu = withStyles({
  paper: {
    paddingTop: 10,
    paddingBottom: 10,
    border: '1px solid #D4D7DD',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    borderRadius: 8
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    paddingLeft: 29,
    paddingRight: 67,
    paddingTop: 7,
    paddingBottom: 7,
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const authService = new AuthService();

export default function TopMenu(props: { showDocuments: boolean; showUserMenu?: boolean }) {
  const history = useHistory();
  const { setIsOpen } = useTour();
  const { showDocuments, showUserMenu } = props;
  const {
    account,
    isFreeSubscription,
    setAccount,
    setSubscription,
    setIsFreeSubscription,
    setCurrentSubscriptionType
  } = useGlobalState();
  const [userMenuEl, setUserMenuEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    document.title = 'Homer';
  }, [history]);

  useEffect(() => {
    if (account && account.daysRest < 0 && isFreeSubscription) {
      history.push('/expired');
    }
  }, [account]);

  return (
    <div className="top-menu">
      <div className="menu-left-container">
        <div className="title">
          <a href="/">Homer</a>
        </div>
        {showDocuments && (
          <a href="/">
            <div className="documents">Documents</div>
          </a>
        )}
      </div>
      <div className="menu-right-container">
        {isFreeSubscription && (
          <>
            <div className="remain" data-tut="reactour__possibilities">
              {account?.daysRest} {account?.daysLabel} remaining.{' '}
              <u
                style={{ cursor: 'pointer' }}
                onClick={e => {
                  e.preventDefault();
                  history.push({ pathname: '/profile', state: { initialTab: ProfileTabs.Account } });
                }}
              >
                Upgrade now
              </u>
            </div>
            <div className="plan">Free plan</div>
          </>
        )}
        {showUserMenu !== false && (
          <div className="user-menu" data-tut="reactour__profile_menu">
            <Button
              aria-controls="user-menu"
              aria-haspopup="true"
              style={{ backgroundColor: 'transparent' }}
              variant="text"
              color="primary"
              className="profile-name"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setUserMenuEl(event.currentTarget);
              }}
            >
              {account?.email}
            </Button>

            <StyledMenu
              id="user-menu"
              anchorEl={userMenuEl}
              keepMounted
              open={Boolean(userMenuEl)}
              onClose={() => {
                setUserMenuEl(null);
              }}
            >
              <StyledMenuItem
                onClick={() => {
                  setUserMenuEl(null);
                  history.push({ pathname: '/profile', state: { initialTab: ProfileTabs.Account } });
                }}
                className="custom-popup-menu-item"
              >
                <img src={require('../../assets/images/m-account.svg')} alt="Plan & Payment" />
                Plan & Payment
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  setUserMenuEl(null);
                  history.push({ pathname: '/profile', state: { initialTab: ProfileTabs.Invoices } });
                }}
                className="custom-popup-menu-item"
              >
                <img src={require('../../assets/images/m-invoices.svg')} alt="Invoices" />
                Invoices
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  setUserMenuEl(null);
                  history.push({ pathname: '/profile', state: { initialTab: ProfileTabs.Feedback } });
                }}
                className="custom-popup-menu-item"
              >
                <img src={require('../../assets/images/m-feedback.svg')} alt="Feedback" />
                Feedback
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  setUserMenuEl(null);
                  history.push({ pathname: '/profile', state: { initialTab: ProfileTabs.Help } });
                }}
                className="custom-popup-menu-item"
              >
                <img src={require('../../assets/images/m-feedback.svg')} alt="Help" />
                Help
              </StyledMenuItem>
              {window.location.pathname === '/' && (
                <StyledMenuItem
                  onClick={() => {
                    setUserMenuEl(null);
                    setIsOpen(true);
                  }}
                  className="custom-popup-menu-item"
                >
                  <img src={require('../../assets/images/m-feedback.svg')} alt="Tour" />
                  Tour
                </StyledMenuItem>
              )}
              <StyledMenuItem
                onClick={() => {
                  setUserMenuEl(null);
                  setAccount(undefined);
                  setSubscription(undefined);
                  setIsFreeSubscription(undefined);
                  setCurrentSubscriptionType(SubscriptionType.Undefined);
                  authService.logout();
                }}
                className="custom-popup-menu-item"
              >
                <img src={require('../../assets/images/m-logout.svg')} alt="Log out" />
                Log out
              </StyledMenuItem>
            </StyledMenu>
          </div>
        )}
      </div>
    </div>
  );
}
