import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import './toasts.scss';

function ToastErrorMessage(msg: string) {
  return ({ closeToast }) => (
    <div className="toast-content">
      <div className="toast-icon">
        <img src={require('../../assets/images/ic-error.svg')} alt="Error" />
      </div>
      <div className="toast-message">{msg}</div>
      <div className="toast-close">
        <img
          onClick={closeToast}
          style={{ marginTop: -10 }}
          src={require('../../assets/images/toast-close-btn.svg')}
          alt="Close"
        />
      </div>
    </div>
  );
}

function ToastInfoMessage(msg: string) {
  return ({ closeToast }) => (
    <div className="toast-content">
      <div className="toast-icon">
        <img src={require('../../assets/images/ic-ok.svg')} alt="Ok" />
      </div>
      <div className="toast-message">{msg}</div>
      <div className="toast-close">
        <img
          onClick={closeToast}
          style={{ marginTop: -10 }}
          src={require('../../assets/images/toast-close-btn.svg')}
          alt="Close"
        />
      </div>
    </div>
  );
}

const opts: ToastOptions = {
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

export const goToastError = (msg: string, innerOpts?: ToastOptions) =>
  toast.error(ToastErrorMessage(msg), innerOpts || opts);
export const goToastInfo = (msg: string, innerOpts?: ToastOptions) =>
  toast.info(ToastInfoMessage(msg), innerOpts || opts);
