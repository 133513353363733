/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/camelcase, @typescript-eslint/ban-ts-ignore  */
import { LocalStorageService } from "../../shared/services";

export class TokensService {
  localStorageService = new LocalStorageService();

  getAccessToken(): string | null {
    return this.localStorageService.getFromStorage('access_token');
  }

  setAccessToken(token: string): void {
    this.localStorageService.saveToStorage({ access_token: token });
  }

  getExpiration(): string | null {
    return this.localStorageService.getFromStorage('expires_at');
  }

  removeExpiration(): void {
    this.localStorageService.removeFromStorage('expires_at');
  }

  setExpiration(expiresAt: number): void {
    this.localStorageService.saveToStorage({ expires_at: expiresAt.toString() });
  }

  isAccessTokenExpired(): boolean {
    // @ts-ignore
    const expiration = +this.localStorageService.getFromStorage('expires_at');

    return isNaN(expiration) || expiration < Date.now();
  }

  removeAccessToken(): void {
    this.localStorageService.removeFromStorage('access_token');
  }

  getRefreshToken(): string | null {
    return this.localStorageService.getFromStorage('refresh_token');
  }

  setRefreshToken(token: string): void {
    this.localStorageService.saveToStorage({ refresh_token: token });
  }

  removeRefreshToken(): void {
    this.localStorageService.removeFromStorage('refresh_token');
  }
}
