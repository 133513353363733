import React, { useState } from 'react';
import axios from 'axios';
import './checkoutForm.scss';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';
import { MsgStripeIsNull } from '../../shared/constants/messages';
import { StripeCardElement } from '@stripe/stripe-js';

const options = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

export default function CheckoutForm(props: {
  priceId: string;
  priceAmount: number;
  onComplete: (err: string | undefined, subscription?: any) => void;
}) {
  const { priceId, priceAmount, onComplete } = props;
  const [processing, setProcessing] = useState<boolean>(false);
  const [creditCardValid, setCreditCardValid] = useState<boolean>();
  const stripe = useStripe();
  const elements = useElements();
  const cardElement = elements?.getElement(CardElement);
  cardElement?.on('change', event => {
    setCreditCardValid(!event.error);
  });

  function onSubscriptionComplete(result) {
    setProcessing(false);
    localStorage.removeItem('latestInvoicePaymentIntentStatus');
    onComplete(undefined, result.subscription);
  }

  function handlePaymentThatRequiresCustomerAction({ subscription, invoice, priceId, paymentMethodId, isRetry }) {
    if (!stripe) {
      throw Error(MsgStripeIsNull);
    }
    if (subscription && subscription.status === 'active') {
      return { subscription, priceId, paymentMethodId };
    }

    const paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId
        })
        .then((result: any) => {
          if (result.error) {
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId
              };
            }
          }
        })
        .catch(error => {
          throw error;
        });
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId }) {
    if (subscription.status === 'active') {
      return { subscription, priceId, paymentMethodId };
    } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status);
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function createSubscription({ paymentMethodId, priceId }) {
    return axios
      .post(
        '/payment/create-subscription',
        JSON.stringify({
          paymentMethodId: paymentMethodId,
          priceId: priceId
        })
      )
      .then(response => response.data)
      .then(result => {
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result
        };
      })
      .then(handlePaymentThatRequiresCustomerAction as any)
      .then(handleRequiresPaymentMethod)
      .then(onSubscriptionComplete)
      .catch(error => {
        localStorage.removeItem('latestInvoicePaymentIntentStatus');
        setProcessing(false);
        const stderr = 'Something went wrong with your payment';
        if (error.response && error.response.data && error.response.data.error) {
          onComplete(error.response.data.error.message || stderr);
        } else {
          onComplete(stderr);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId, priceId }) {
    return axios
      .post(
        '/payment/retry-invoice',
        JSON.stringify({
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId
        })
      )
      .then(result => ({
        invoice: result,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        isRetry: true
      }))
      .then(handlePaymentThatRequiresCustomerAction as any)
      .then(onSubscriptionComplete)
      .catch(error => {
        setProcessing(false);
        onComplete(JSON.stringify(error));
      });
  }

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setProcessing(true);
    const latestInvoicePaymentIntentStatus = localStorage.getItem('latestInvoicePaymentIntentStatus');
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement as StripeCardElement
    });
    if (error || !paymentMethod) {
      // cardElement?.clear();
      onComplete(error?.message || 'payment method is missing');
      setProcessing(false);
      // console.log('[createPaymentMethod error]', error);
    } else {
      // console.log('[PaymentMethod]', paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        const invoiceId = localStorage.getItem('latestInvoiceId');
        retryInvoiceWithNewPaymentMethod({
          paymentMethodId,
          invoiceId,
          priceId
        });
      } else {
        createSubscription({ paymentMethodId, priceId });
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="card-holder-input card-input">
        <CardElement className="sr-input sr-card-element" options={options} />
      </div>
      <div className="payment-actions">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="generic-action-button confirm-button"
          disabled={!stripe || processing || !creditCardValid}
        >
          {processing ? 'Processing...' : `Pay $${priceAmount}`}
        </Button>
      </div>
    </form>
  );
}
