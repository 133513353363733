import Button from '@material-ui/core/Button';
import React, { MouseEventHandler } from 'react';
import noDocuments from '../../assets/images/no-documents.svg';
import notFound from '../../assets/images/404.svg';
import noResults from '../../assets/images/no-results.svg';
import './infoHolder.scss';

const imageObjects = {
  'no-documents': noDocuments,
  'not-found': notFound,
  'no-results': noResults
};

export default function InfoHolder(props: {
  title: string;
  text: string;
  buttonLabel?: string;
  variant: string;
  onClick?: MouseEventHandler;
}) {
  const { title, text, buttonLabel, variant, onClick } = props;
  const imageObject = imageObjects[variant];
  return (
    <div className="info-holder-container">
      {imageObject && <img src={imageObject} alt={title} className="info-holder-image" />}
      <div className="info-holder-center info-container-title">{title}</div>
      <div className="info-holder-center info-container-text">{text}</div>
      {buttonLabel && onClick && (
        <div className="info-holder-button">
          <Button onClick={onClick} color="primary" variant="contained">
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  );
}
