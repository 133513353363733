import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import axios from 'axios';
import './documents.scss';
import TopMenu from '../shared/TopMenu';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createTheme, makeStyles, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import { ErrorData } from '../../shared';
import PromptModal from '../shared/PromptModal';
import { getErrorContent } from '../../shared/functions/process-error';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { materialTheme } from '../shared/MaterialTheme';
import { goToastError } from '../shared/Toasts';
import SimpleFormModal from '../shared/SimpleFormModal';
import Box from '@material-ui/core/Box';
import InfoHolder from '../shared/InfoHolder';
import {
  MsgCantDeleteDocument,
  MsgCantDuplicateDocument,
  MsgCantRenameDocument,
  MsgCantGetDocumentsList,
  MsgCantUploadDocument,
  MsgDocumentTooLarge,
  MsgDocumentDeletePrompt
} from '../../shared/constants/messages';
import Pagination from '@material-ui/lab/Pagination';
import { Tooltip } from '@material-ui/core';
import Templates from './Templates';
import { useGlobalState } from '../shared/GlobalContext';
import WelcomeModal from '../shared/WelcomeModal';
// import CancelIcon from '@material-ui/icons/Cancel';

const StyledMenu = withStyles({
  paper: {
    paddingTop: 5,
    paddingBottom: 5,
    border: '1px solid #D4D7DD',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    borderRadius: 8
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
));

export interface UIDocument {
  id: number;
  title: string;
  createTimeLabel: string;
  updateTimeLabel: string;
  content?: string;
  linkHash?: string;
  template?: number;
}

interface DocPrompt {
  message: string;
  documentId: number;
}

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: '#495864',
      fontFamily: 'Inter'
    }
  }
}));
const pageSize = 25;

export default function Documents() {
  const history = useHistory();
  const classes = useStyles();
  const { templates } = useGlobalState();

  const [renameDocumentPopupShow, setRenameDocumentPopupShow] = useState<boolean>(false);
  const [documentRenameTitle, setDocumentRenameTitle] = useState<string>('');
  const [uploadDocumentPopupShow, setUploadDocumentPopupShow] = useState<boolean>(false);
  const [sharePopupShow, setSharePopupShow] = useState<boolean>(false);
  const [welcomeShow, setWelcomeShow] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<UIDocument>();
  const [searchDocumentTitle, setSearchDocumentTitle] = useState<string>('');
  const [searchDocumentTitleToSend] = useDebounce(searchDocumentTitle, 500);
  const [documents, setDocuments] = useState<UIDocument[]>([]);
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState();
  const [deleteDocumentPrompt, setDeleteDocumentPrompt] = useState<DocPrompt | undefined>();
  const [documentMenuEl, setDocumentMenuEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(0);

  const templatesHash = templates?.reduce((acc, item) => {
    acc.set(item.id, item.name);
    return acc;
  }, new Map<number, string>());
  const getDocuments = async () =>
    axios.get(`/document/list`, { params: { title: searchDocumentTitleToSend, page: page - 1, pageSize } });
  const fillDocuments = async () => {
    try {
      setDocumentsLoading(true);
      setRenameDocumentPopupShow(false);
      const res = await getDocuments();
      setDocuments(res.data.records);
      setPages(Math.ceil(res.data.total / pageSize));
    } catch (error) {
      goToastError(getErrorContent(error, MsgCantGetDocumentsList));
    } finally {
      setTimeout(() => {
        setDocumentsLoading(false);
        if (localStorage.getItem('homer_guide')) {
          localStorage.removeItem('homer_guide');
          setWelcomeShow(true);
        }
      }, 500);
    }
  };

  const deleteDocument = (id: number) => {
    axios
      .post(`/document/delete/${id}`)
      .then(fillDocuments)
      .catch((error: { response: { data: ErrorData } } | ErrorData) => {
        goToastError(getErrorContent(error, MsgCantDeleteDocument));
      });
  };
  const duplicateDocument = (id: number) => {
    axios
      .post(`/document/duplicate/${id}`)
      .then(fillDocuments)
      .catch((error: { response: { data: ErrorData } } | ErrorData) => {
        goToastError(getErrorContent(error, MsgCantDuplicateDocument));
      });
  };
  const renameDocument = (id: number, title: string) => {
    axios
      .post(`/document/rename/${id}`, JSON.stringify({ title }))
      .then(fillDocuments)
      .catch((error: { response: { data: ErrorData } } | ErrorData) => {
        goToastError(getErrorContent(error, MsgCantRenameDocument));
      });
  };

  const rowMenu = (d: UIDocument) => (
    <>
      <Button
        aria-controls="document-menu"
        aria-haspopup="true"
        style={{ backgroundColor: 'transparent' }}
        className="doc-menu-area"
        variant="text"
        color="primary"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setSelectedDocument(d);
          setDocumentRenameTitle(d.title);
          setDocumentMenuEl(event.currentTarget);
        }}
      ></Button>
      <StyledMenu
        id="document-menu"
        anchorEl={documentMenuEl}
        keepMounted
        open={Boolean(documentMenuEl)}
        onClose={() => {
          setDocumentMenuEl(null);
        }}
      >
        {/* <MenuItem
          onClick={() => {
            setDocumentMenuEl(null);
            setRenameDocumentPopupShow(true);
          }}
          className="custom-popup-menu-item"
        >
          <img src={require('../../assets/images/edit.svg')} alt="Rename" />
          Rename
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setDocumentMenuEl(null);
            duplicateDocument(selectedDocument?.id || 0);
          }}
          className="custom-popup-menu-item"
        >
          <img src={require('../../assets/images/duplicate.svg')} alt="Duplicate" />
          <span className="custom-popup-menu-item-text">Duplicate</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDocumentMenuEl(null);
            setDeleteDocumentPrompt({ message: MsgDocumentDeletePrompt, documentId: selectedDocument?.id || 0 });
          }}
          className="custom-popup-menu-item"
        >
          <img src={require('../../assets/images/delete.svg')} alt="Delete" />
          <span className="custom-popup-menu-item-text">Delete</span>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            setDocumentMenuEl(null);
            setSharePopupShow(true);
          }}
          className="custom-popup-menu-item"
        >
          <img src={require('../../assets/images/share.svg')} alt="Share" />
          Share
        </MenuItem> */}
      </StyledMenu>
    </>
  );
  const getGotoEditing = (currentDocument: UIDocument) => () => {
    history.push({ pathname: '/dashboard', state: { docId: currentDocument.id, docTitle: currentDocument.title } });
  };
  const createDocument = (title: string, template?: number) => {
    setSearchDocumentTitle('');
    axios
      .post(`/document/create`, JSON.stringify({ title, template }))
      .then(res => {
        getGotoEditing(res.data)();
      })
      .catch((error: { response: { data: ErrorData } } | ErrorData) => {
        goToastError(getErrorContent(error, MsgCantRenameDocument));
      });
  };
  const fileUpload = (file: any) => {
    const url = '/document/upload';
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    axios
      .post(url, formData, config)
      .then(res => {
        setFileToUpload(undefined);
        setUploadDocumentPopupShow(false);
        // setSearchDocumentTitle('');
        // fillDocuments(searchDocumentTitle)();
        getGotoEditing(res.data)();
      })
      .catch((error: { response: { data: ErrorData } } | ErrorData) => {
        setFileToUpload(undefined);
        setUploadDocumentPopupShow(false);
        goToastError(getErrorContent(error, MsgCantUploadDocument));
      });
  };
  const onFileToUploadChange = (e: any) => {
    if (e.target.files[0].size > 1024 * 1024) {
      goToastError(getErrorContent({}, MsgDocumentTooLarge));
    } else {
      setFileToUpload(e.target.files[0]);
    }
  };
  const uploadBtnTheme = createTheme({
    palette: { primary: { main: '#0967D2' } },
    typography: {
      button: {
        textTransform: 'none',
        height: 44,
        borderRadius: 4,
        paddingLeft: 5,
        paddingRight: 5
      }
    }
  });
  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    fillDocuments();
  }, [page, searchDocumentTitleToSend]);

  return (
    <MuiThemeProvider theme={materialTheme}>
      <ToastContainer closeButton={false} />
      <TopMenu showDocuments={false} />
      <div className="documents-root">
        <div className="documents-holder">
          <div className="documents-container">
            <Templates
              onDocumentCreate={(template: number) => {
                createDocument('Untitled document', template);
              }}
            />
            <div className="documents-header">
              <div className="left-container">Documents</div>
              <div className="right-container">
                <input
                  type="text"
                  className="searchTerm"
                  value={searchDocumentTitle}
                  onChange={e => setSearchDocumentTitle(e.target.value)}
                  placeholder="Search"
                />
                <div className="create-new-button-container">
                  <MuiThemeProvider theme={uploadBtnTheme}>
                    <Button
                      data-tut="reactour__upload"
                      onClick={() => {
                        setUploadDocumentPopupShow(true);
                      }}
                      color="primary"
                      variant="text"
                      className="upload-button"
                    >
                      <img src={require('../../assets/images/upload.svg')} style={{ marginRight: 10 }} />
                      Upload
                    </Button>
                  </MuiThemeProvider>
                  <Box m="5px" />
                  <Button
                    data-tut="reactour__doc_blank"
                    aria-controls="create-new-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    className="create-new-button generic-action-button"
                    onClick={() => {
                      createDocument('Untitled document');
                    }}
                  >
                    Create new
                  </Button>
                </div>
              </div>
            </div>
            {documentsLoading && (
              <div className="documents-content-container">
                <div className="documents-table">
                  <div style={{ display: 'table-row' }}>Please wait...</div>
                </div>
              </div>
            )}
            {!documentsLoading && documents && documents.length <= 0 && !searchDocumentTitleToSend && (
              <InfoHolder
                title="No documents yet"
                text="There are no documents for now, but you can create one."
                buttonLabel="Create new"
                variant="no-documents"
                onClick={() => {
                  createDocument('Untitled document');
                }}
              />
            )}
            {!documentsLoading && documents && documents.length <= 0 && searchDocumentTitleToSend && (
              <InfoHolder
                title="No result found"
                text="Sorry, but we could not find what you’re looking for."
                variant="no-results"
              />
            )}
            {!documentsLoading && documents && documents.length > 0 && (
              <>
                <div className="documents-content-container">
                  <div className="documents-table">
                    <div className="tr doc-table-header">
                      <div className="th documents-table-main-column">TITLE</div>
                      <div className="th documents-table-other-column">LAST UPDATED</div>
                      <div className="th documents-table-other-column" style={{ paddingLeft: 70 }}>
                        ACTIONS
                      </div>
                    </div>
                    {documents.map((document: UIDocument) => (
                      <div key={document.id} className="tr">
                        <div className="td" onClick={getGotoEditing(document)} data-tut="reactour__doc_title">
                          {document?.template && templatesHash?.get(document.template) && (
                            <img
                              className="doc-temp-glyph"
                              src={require(`../../assets/images/templates/doc-temp-${templatesHash?.get(
                                document.template
                              )}.svg`)}
                            />
                          )}
                          {document.title}
                        </div>
                        {/* <div className="td" onClick={getGotoEditing(document)}>
                          {document.createTimeLabel}
                        </div> */}
                        <div className="td documents-table-other-column" onClick={getGotoEditing(document)}>
                          {document.updateTimeLabel}
                        </div>
                        <div className="td documents-table-other-column">
                          <div style={{ marginLeft: 40, marginRight: 20 }}>
                            <Tooltip title="Rename">
                              <img
                                onClick={() => {
                                  setSelectedDocument(document);
                                  setDocumentRenameTitle(document.title);
                                  setRenameDocumentPopupShow(true);
                                }}
                                style={{ width: 15, height: 15, marginRight: 35 }}
                                src={require('../../assets/images/edit.svg')}
                                alt="Rename"
                              />
                            </Tooltip>
                            <Tooltip title="Share">
                              <img
                                onClick={() => {
                                  setSelectedDocument(document);
                                  setSharePopupShow(true);
                                }}
                                style={{ width: 15, height: 15, marginRight: 35 }}
                                src={require('../../assets/images/share.svg')}
                                alt="Share"
                              />
                            </Tooltip>
                            {rowMenu(document)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="pagination-container" style={{ marginTop: 10 }}>
                    {pages > 1 && (
                      <Pagination count={pages} page={page} onChange={handlePageChange} classes={{ ul: classes.ul }} />
                    )}
                  </div>
                </div>
                {/* <div className="pagination-container">
                  {pages > 1 && (
                    <Pagination count={pages} page={page} onChange={handlePageChange} classes={{ ul: classes.ul }} />
                  )}
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
      <SimpleFormModal
        showFlag={renameDocumentPopupShow}
        setShowFlag={setRenameDocumentPopupShow}
        title="Rename document"
        inputElement={
          <input
            type="text"
            onKeyPress={event => {
              if (event.charCode == 13) {
                renameDocument(selectedDocument?.id || 0, documentRenameTitle);
              }
            }}
            ref={input => input && input.focus()}
            className="new-doc-input"
            value={documentRenameTitle}
            placeholder="Enter a new title for the document"
            onChange={e => setDocumentRenameTitle(e.target.value)}
          />
        }
        okTitle="Rename"
        okAction={() => renameDocument(selectedDocument?.id || 0, documentRenameTitle)}
        okDisabled={!documentRenameTitle || !selectedDocument}
      />
      <SimpleFormModal
        showFlag={uploadDocumentPopupShow}
        setShowFlag={setUploadDocumentPopupShow}
        title="Upload document"
        description={<span className="upload-doc-desc">Supported formats: .doc, .docx, .txt</span>}
        inputElement={
          <span style={{ marginLeft: -12, marginTop: 20, marginBottom: 20 }}>
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain"
              onChange={onFileToUploadChange}
            />
          </span>
        }
        okTitle="Upload"
        okAction={() => fileUpload(fileToUpload)}
        okDisabled={!fileToUpload}
      />
      <SimpleFormModal
        showFlag={sharePopupShow}
        setShowFlag={setSharePopupShow}
        title="Share the document"
        description="Anyone with this link can view the document."
        inputElement={
          <input
            type="text"
            className="new-doc-input"
            value={`${process.env.REACT_APP_CLI_URL}/shared/${selectedDocument?.linkHash}`}
          />
        }
        customOkElement={
          <CopyToClipboard
            text={`${process.env.REACT_APP_CLI_URL}/shared/${selectedDocument?.linkHash}`}
            onCopy={() => {
              setSharePopupShow(false);
            }}
          >
            <button className="generic-action-button">Copy to clipboard</button>
          </CopyToClipboard>
        }
      />
      <PromptModal
        message={deleteDocumentPrompt?.message}
        setMessage={setDeleteDocumentPrompt}
        okAcion={() => {
          deleteDocument(deleteDocumentPrompt?.documentId || 0);
        }}
        // adornment={<CancelIcon style={{ color: 'red', width: 40, height: 40, marginRight: 15 }} />}
      />
      <WelcomeModal showFlag={welcomeShow} setShowFlag={setWelcomeShow} />
    </MuiThemeProvider>
  );
}
