export const MsgCantSaveDocument = `Sorry, can't save the document. Please try again.`;
export const MsgConnectionLost = `Sorry, it seems the connection with the server was lost. Please check the connection and try again. `;
export const MsgCantGetDocumentsList = `Sorry, can't retrieve the documents. Please try again.`;
export const MsgCantGetDocumentTemplatesList = `Sorry, can't retrieve the document templates. Please try again.`;
export const MsgCantDeleteDocument = `Sorry, can't delete the document. Please try again.`;
export const MsgCantDuplicateDocument = `Sorry, can't create a duplicate of the document. Please try again.`;
export const MsgCantRenameDocument = `Sorry, can't rename the document. Please try again.`;
export const MsgCantCreateDocument = `Sorry, can't create the document. Please try again.`;
export const MsgCantUploadDocument = `Sorry, can't upload the document. Please try again.`;
export const MsgDocumentTooLarge = `Sorry, can’t upload the file. This file is too large. Maximum file size allowed is 1MB.`;
export const MsgCantGetInvoicesList = `Sorry, can't retrieve invoices. Please try again.`;
export const MsgCantGetDefaultPaymentMethod = `Sorry, can't get the default payment method. Please try again.`;
export const MsgErrorPaymentMethodCreation = (err: string): string =>
  !err ? `Error during payment method creation.` : `Error during payment method creation: ${err}.`;
export const MsgErrorSubscriptionCreation = (err: string): string => {
  const conclusion = `Please check your email. If you don’t receive a confirmation email, then it’s possible that there is something wrong with your credit card. If this error persists, please write to us at hello@homerwritingapp.com. `;
  return !err
    ? `Error during subscription creation. ${conclusion}`
    : `Error during subscription creation: ${err}. ${conclusion}`;
};
export const MsgCantChangePassword = `Sorry, an error happened, can't change your password. Please try again.`;
export const MsgCantSendFeedback = `Sorry, an error happened, can't send your feedback. Please try again.`;
export const MsgCantCancelSubscription = `Sorry, an error happened, can't cancel your subscription. Please try again.`;
export const MsgCantRecancelSubscription = `Sorry, can't re-cancel your subscription. Please try again.`;
export const MsgCantUpdatePlanToAnnual = `Sorry, can't update your plan to annual. Please try again.`;
export const MsgDocumentSavedAfterMissedConnection = 'Just saved your document.';
export const MsgNewPaymentMethodSet = 'Thank you! New payment method is now set.';
export const MsgPasswordChangedSuccessfully = `Password changed successfully.`;
export const MsgThankYouForFeedback = `Thank you for your feedback!`;
export const MsgServerErrorCheckConnection = `Server error. Please check your connection.`;
export const MsgEmailRequired = 'Please enter email.';
export const MsgPasswordRequired = 'Please enter password.';
export const MsgEmailNotValid = 'Email isn’t valid.';
export const MsgPasswordNotValid = 'Password isn’t valid.';
export const MsgConfirmPasswordRequired = 'Please retype password.';
export const MsgConfirmPasswordNotValid = 'Retyped password isn’t valid.';
export const MsgDocumentDeletePrompt = 'Are you sure?';
export const MsgCancelSubscrptionPrompt = 'Are you sure?';
export const MsgReCancelSubscrptionPrompt = 'Are you sure?';
export const MsgUpgradePlanPrompt = 'Do you want to upgrade your plan?';
export const MsgCantParseTokens = `Can't parse tokens: insufficient data.`;
export const MsgStripeIsNull = 'Stripe is null.';
export const MsgEnterEmail = `Please enter email`;
export const MsgEnterPassword = `Please enter password (minimum 8 characters)`;
