import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './profile.scss';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { PriceDesc, SubscriptionType, useGlobalState } from '../shared/GlobalContext';
import { useHistory } from 'react-router-dom';
import { AuthService } from '../../auth/services/authService';
import { ToastContainer } from 'react-toastify';
import { goToastError } from '../shared/Toasts';

const authService = new AuthService();

export default function Expired(props: any) {
  const history = useHistory();
  const {
    annualPrice,
    monthlyPrice,
    subscription,
    setSubscription,
    setAccount,
    setIsFreeSubscription,
    setCurrentSubscriptionType
  } = useGlobalState();
  const [selectedPrice, setSelectedPrice] = useState<PriceDesc | null>();
  const getPublicStripeKey = async () => axios.get(`/payment/public-key`);
  const stripePromise = getPublicStripeKey().then(keyRes => loadStripe(keyRes.data.publicKey));
  const onAnnualyPlanChanged = ev => {
    if (ev.currentTarget.value === 'on') {
      setSelectedPrice(annualPrice);
    }
  };
  const onMonthlyPlanChanged = ev => {
    if (ev.currentTarget.value === 'on') {
      setSelectedPrice(monthlyPrice);
    }
  };
  const footer = (
    <p style={{ marginTop: 40 }}>
      Have any questions? Contact our support team:{' '}
      <a href="mailto:hello@homerwritingapp.com">hello@homerwritingapp.com</a>
    </p>
  );
  const billingPlans = (
    <div className="billing-plans">
      <div className="tr">
        <div className="td">
          <input
            type="radio"
            id="annualy"
            name="annualy_plan"
            checked={selectedPrice === annualPrice}
            onChange={onAnnualyPlanChanged}
          />
          <label htmlFor="annualy">Billed annualy</label>
        </div>
        <div className="td">
          <input
            type="radio"
            id="monthly"
            name="monthly_plan"
            checked={selectedPrice === monthlyPrice}
            onChange={onMonthlyPlanChanged}
          />
          <label htmlFor="monthly">Billed monthly</label>
        </div>
        <div className="td amount">
          <div className="amount-value">{selectedPrice?.amount}$</div>
          <div className="plan-label">per {selectedPrice === annualPrice ? 'year' : 'month'}</div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (!selectedPrice && annualPrice) {
      setSelectedPrice(annualPrice);
    }
  }, [annualPrice]);

  useEffect(() => {
    if (subscription) {
      history.push('/');
    }
  }, [subscription]);

  return (
    <>
      <ToastContainer closeButton={false} />
      <div className="profile-root">
        <div className="account-details">
          <div>
            <div className="header-labels">
              <h3>Homer</h3>
              <h6>Upgrade for Uninterrupted Access</h6>
              <p>
                ... or{' '}
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    setAccount(undefined);
                    setSubscription(undefined);
                    setIsFreeSubscription(undefined);
                    setCurrentSubscriptionType(SubscriptionType.Undefined);
                    authService.logout();
                  }}
                >
                  log out
                </a>
              </p>
            </div>
            {selectedPrice && (
              <>
                {billingPlans}
                <div>
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      priceId={selectedPrice.id}
                      priceAmount={selectedPrice.amount}
                      onComplete={(err: string | undefined, subscription?: any) => {
                        if (!err && subscription) {
                          setSubscription(subscription);
                        } else {
                          const head = err
                            ? `Error during subscription creation: ${err}.`
                            : `Error during subscription creation.`;
                          const tail = `Please, check your email. If this email is missing then it something wrong with your credit card.`;
                          goToastError(`${head} ${tail}`, {
                            autoClose: false,
                            closeOnClick: true,
                            draggable: true,
                            progress: undefined
                          });
                        }
                      }}
                    />
                  </Elements>
                </div>
                {footer}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
