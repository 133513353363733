import React from 'react';
import { UIDocumentTemplate, useGlobalState } from '../shared/GlobalContext';
import './templates.scss';

export interface TemplatesProps {
  onDocumentCreate: (template: number) => void;
}

export default function Templates(props: TemplatesProps) {
  const { onDocumentCreate } = props;
  const { templates } = useGlobalState();

  if (!templates) {
    return null;
  }

  return (
    <div className="doc-templates-container">
      {templates.map((template: UIDocumentTemplate) => (
        <div
          className="doc-temp-holder"
          data-tut="reactour__doc_template"
          onClick={() => {
            onDocumentCreate(template.id);
          }}
        >
          <div>
            <img
              src={require(`../../assets/images/templates/doc-temp-${template.name}.svg`)}
              title={template.label}
              alt={template.label}
            />
          </div>
          <div>{template.label}</div>
        </div>
      ))}
    </div>
  );
}
