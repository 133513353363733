import React from 'react';
import './tour.scss';

export const tourConfig = [
  {
    selector: '[data-tut="reactour__doc_title"]',
    content: function() {
      return (
        <div className="tour-step">
          <div>See Homer in Action</div>
          <div>Click on “Sample Document” to see how Homer works its magic, highlighting word difficulty, long paragraphs and sentences, overused adverbs, and more.</div>
        </div>
      );
    }
  },
  {
    selector: '[data-tut="reactour__doc_template"]',
    content: function() {
      return (
        <div className="tour-step">
          <div>Save Time with Templates</div>
          <div>Starting from a blank page is intimidating. Save yourself time and anxiety by using one of Homer’s many templates that come prefilled with helpful content.</div>
        </div>
      );
    }
  },
  {
    selector: '[data-tut="reactour__upload"]',
    content: function() {
      return (
        <div className="tour-step">
          <div>Improve Your Existing Work</div>
          <div>Already have a document you need to analyze? Upload it here in txt, doc, or docx format.</div>
        </div>
      );
    }
  },
  {
    selector: '[data-tut="reactour__doc_blank"]',
    content: function() {
      return (
        <div className="tour-step">
          <div>Create a New Document</div>
          <div>Start from scratch by clicking “Create new.”</div>
        </div>
      );
    }
  },
  {
    selector: '[data-tut="reactour__profile_menu"]',
    content: function() {
      return (
        <div className="tour-step">
          <div>Manage Your Profile</div>
          <div>Editing your profile, changing your password, sending feedback, asking for help, and viewing invoices is easy一simply click on your email address.</div>
        </div>
      );
    }
  },
  {
    selector: '[data-tut="reactour__possibilities"]',
    content: function() {
      return (
        <div className="tour-step">
          <div>Harness Homer’s Power</div>
          <div>Upgrade your plan now to take advantage of all that Homer can do with a monthly subscription (you can cancel anytime).</div>
        </div>
      );
    }
  },
];
