import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Sidebar, { initStatistic } from '../../Sidebar';
import { Mode } from '../../nlp-models/mode.enum';
import { Analytics } from '../../nlp-models/analytics.model';
import { getTextContent, MyEditor, TextContentContentBlocks } from '../../EditorWorker';
import './dashboard.scss';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Structure from './Structure';
import Stats from './Stats';
import { useParams } from 'react-router-dom';
import TopMenu from '../shared/TopMenu';
import InfoHolder from '../shared/InfoHolder';
import { EditorState } from 'draft-js';
import Analyzer from '../../Analyzer';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#12cdf8'
    }
  }
});

interface ParamTypes {
  linkHash: string;
}

export default function SharedDocument(props: any) {
  const { linkHash } = useParams<ParamTypes>();
  const [statistic, setStatistic] = useState<Analytics>(initStatistic);
  const [activeMode, setActiveMode] = useState<Mode>(Mode.ANALYZE);
  const [sideBarVisible, setSideBarVisible] = useState<boolean>(true);
  const [initContent, setInitContent] = useState<string | undefined>();
  const [title, setTitle] = useState<string>();
  const [showStructure, setShowStructure] = useState<boolean>(false);
  const [currentEditorState, setCurrentEditorState] = useState<EditorState>();
  const [contentToAnalyze, setContentToAnalyze] = useState<TextContentContentBlocks | undefined>();
  const [serverError, setServerError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const myRef = useRef<any>();

  const readContent = async () => {
    try {
      const res = await axios.get(`/document/share/${linkHash}`);
      if (!res.data) {
        setServerError('Document is not found');
      } else {
        setInitContent(res.data.content);
        setTitle(res.data.title);
      }
    } catch (error) {
      setServerError('Document is not found');
    }
  };

  useEffect(() => {
    (async () => {
      await readContent();
      if (activeMode === Mode.ANALYZE) {
        if (currentEditorState && !loading) {
          setContentToAnalyze(getTextContent(currentEditorState));
        }
      }
    })();
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Analyzer
        content={contentToAnalyze}
        onStart={() => {
          setLoading(true);
        }}
        onCompleted={(analytics: Analytics) => {
          setStatistic(analytics);
          setLoading(false);
        }}
      />

      <div className="hmr-container">
        <div className="hmr-toolbar">
          <TopMenu showDocuments={false} showUserMenu={false} />
        </div>

        {!serverError ? (
          <>
            <div className="hmr-dashboard-body">
              <div className="hmr-editor">
                {showStructure && (
                  <Structure
                    ref={myRef}
                    content={initContent || ''}
                    onFadeEnd={() => {
                      setShowStructure(false);
                    }}
                  />
                )}
                {!showStructure && activeMode === Mode.STATS && statistic && (
                  <Stats
                    analytics={statistic}
                    onContentAnalyze={() => {
                      if (currentEditorState && !loading) {
                        setContentToAnalyze(getTextContent(currentEditorState));
                      }
                    }}
                  />
                )}
                {!showStructure && initContent !== undefined && activeMode !== Mode.STATS && (
                  <MyEditor
                    initContent={initContent}
                    analytics={statistic}
                    onContentInit={(editorState: EditorState) => {
                      setCurrentEditorState(editorState);
                    }}
                    onContentChange={() => null}
                    onContentAnalyze={(editorState: EditorState) => {
                      if (!loading) {
                        setCurrentEditorState(editorState);
                        setContentToAnalyze(getTextContent(editorState));
                      }
                    }}
                    activeMode={activeMode}
                    readOnly={true}
                    title={title || ''}
                    documentHash=""
                    saveFlag={false}
                  />
                )}
              </div>

              {sideBarVisible ? (
                <div className="hmr-sidebar">
                  <div className="sidebar-container">
                    <div className="collapse-sidebar">
                      <a
                        onClick={() => {
                          setSideBarVisible(!sideBarVisible);
                        }}
                      >
                        <img src={require('../../assets/images/collapse.svg')} alt=">>" />
                      </a>
                    </div>
                    <Sidebar
                      onShowStructureSwitch={(show: boolean) => {
                        if (show) {
                          setShowStructure(show);
                        } else {
                          if (myRef.current) {
                            myRef.current.goFade();
                          }
                        }
                      }}
                      switchMode={setActiveMode}
                      activeMode={activeMode}
                      statistic={statistic}
                      readOnly={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="sidebar-collapsed-container">
                  <div className="collapse-sidebar">
                    <a
                      onClick={() => {
                        setSideBarVisible(!sideBarVisible);
                      }}
                    >
                      <img src={require('../../assets/images/collapse.svg')} alt=">>" />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div style={{ marginTop: 30 }}>
            <InfoHolder
              title="Whoops!"
              text="Sorry, but we could not find the page you’re looking for"
              variant="not-found"
            />
          </div>
        )}
      </div>
      {loading && (
        <div className="progress-text-check">
          <p>Analyzing results...</p>
          <div className="ball-loader">
            <div className="ball-loader-ball ball2"></div>
            <div className="ball-loader-ball ball3"></div>
            <div className="ball-loader-ball ball1"></div>
          </div>
        </div>
      )}
    </MuiThemeProvider>
  );
}
