import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDebounce } from 'use-debounce';
import Sidebar, { initStatistic } from '../../Sidebar';
import TopMenu from '../shared/TopMenu';
import { Mode } from '../../nlp-models/mode.enum';
import { Analytics } from '../../nlp-models/analytics.model';
import Editor, { getHtmlContent, getTextContent, TextContentContentBlocks } from '../../EditorWorker';
import './dashboard.scss';
import { ErrorData } from '../../shared';
import { useGlobalState } from '../shared/GlobalContext';
import useInterval from '@use-it/interval';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Structure from './Structure';
import Stats from './Stats';
import { materialTheme } from '../shared/MaterialTheme';
import { getErrorContent, isSystemError } from '../../shared/functions/process-error';
import { goToastError, goToastInfo } from '../shared/Toasts';
import {
  MsgCantSaveDocument,
  MsgConnectionLost,
  MsgDocumentSavedAfterMissedConnection
} from '../../shared/constants/messages';
import Analyzer from '../../Analyzer';
import { EditorState } from 'draft-js';

export default function Dashboard(props: any) {
  const documentId = props.location.state.docId;
  const { lostConnectionFlag, setLostConnectionFlag } = useGlobalState();
  const [statistic, setStatistic] = useState<Analytics>(initStatistic);
  const [activeMode, setActiveMode] = useState<Mode>(Mode.WRITE);
  const prevActiveModeRef = useRef<any>();
  useEffect(() => {
    prevActiveModeRef.current = activeMode;
  });
  const prevActiveMode = prevActiveModeRef.current;
  const [documentTitle, setDocumentTitle] = useState<string>();
  const [documentTemplateGuide, setDocumentTemplateGuide] = useState<string>();
  const [sideBarVisible, setSideBarVisible] = useState<boolean>(true);
  const [initContent, setInitContent] = useState<string | undefined>();
  const [documentHash, setDocumentHash] = useState<string>();
  const [newContent, setNewContent] = useState<string | undefined>();
  const [contentToAnalyze, setContentToAnalyze] = useState<TextContentContentBlocks | undefined>();
  const [currentEditorState, setCurrentEditorState] = useState<EditorState>();
  const [showStructure, setShowStructure] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [contentToSave] = useDebounce(newContent, 500);
  const [loading, setLoading] = useState<boolean>(false);
  const readContent = async () => {
    try {
      const res = await axios.get(`/document/content/${documentId}`);
      setInitContent(res.data.content);
      setDocumentHash(res.data.linkHash);
      setDocumentTitle(res.data.title);
      if (res.data.templateRecord) {
        setDocumentTemplateGuide(res.data.templateRecord.guide || '');
      }
    } catch (error) {
      if (!lostConnectionFlag) {
        const systemError = isSystemError(error);
        if (systemError) {
          setLostConnectionFlag(true);
        }
        goToastError(getErrorContent(error, MsgConnectionLost));
      }
    }
  };

  const saveContent = (id: number, content: string) => {
    setSaving(true);
    axios
      .post(`/document/content/${id}`, JSON.stringify({ content }))
      .then(() => {
        if (lostConnectionFlag) {
          goToastInfo(MsgDocumentSavedAfterMissedConnection);
        }
        setSaving(false);
        setLostConnectionFlag(false);
      })
      .catch((error: { response: { data: ErrorData } } | ErrorData) => {
        setSaving(false);
        if (!lostConnectionFlag) {
          const systemError = isSystemError(error);
          if (systemError) {
            setLostConnectionFlag(true);
          }
          goToastError(getErrorContent(error, MsgCantSaveDocument));
        }
      });
  };

  const myRef = useRef<any>();

  useInterval(() => {
    if (contentToSave && lostConnectionFlag) {
      saveContent(documentId, contentToSave);
    }
  }, 2000);

  useEffect(() => {
    if (contentToSave !== undefined) {
      saveContent(documentId, contentToSave);
    }
  }, [contentToSave]);

  useEffect(() => {
    if (activeMode === Mode.STATS) {
      if (currentEditorState) {
        setContentToAnalyze(getTextContent(currentEditorState));
      }
      setInitContent(undefined);
    }
    if (activeMode !== Mode.STATS) {
      (async () => {
        await readContent();
        if (activeMode === Mode.ANALYZE && prevActiveMode !== Mode.STATS) {
          if (currentEditorState) {
            setContentToAnalyze(getTextContent(currentEditorState));
          }
        }
      })();
    }
  }, [activeMode]);

  useEffect(() => {
    if (documentTitle) {
      document.title = documentTitle;
    }
  }, [documentTitle]);

  useEffect(() => {
    (async () => {
      await readContent();
      if (activeMode === Mode.ANALYZE) {
        if (currentEditorState) {
          setContentToAnalyze(getTextContent(currentEditorState));
        }
      }
    })();
  }, []);

  return (
    <MuiThemeProvider theme={materialTheme}>
      <ToastContainer closeButton={false} />
      <Analyzer
        content={contentToAnalyze}
        onStart={() => {
          setLoading(true);
        }}
        onCompleted={(analytics: Analytics) => {
          setStatistic(analytics);
          setLoading(false);
        }}
      />

      <div className="hmr-container">
        <div className="hmr-toolbar">
          <TopMenu showDocuments={true} />
        </div>
        <div className="hmr-dashboard-body">
          <div className="hmr-editor">
            {((!showStructure && initContent === undefined && !documentHash && activeMode !== Mode.STATS) ||
              ((activeMode === Mode.ANALYZE || activeMode === Mode.WRITE) && initContent === undefined)) && (
              <div className="documents-content-container" style={{ marginTop: 20 }}>
                <div className="documents-table">
                  <div style={{ display: 'table-row' }}>Please wait...</div>
                </div>
              </div>
            )}
            {showStructure && currentEditorState && (
              <Structure
                ref={myRef}
                content={getHtmlContent(currentEditorState) || ''}
                onFadeEnd={() => {
                  setShowStructure(false);
                }}
              />
            )}
            {!showStructure && activeMode === Mode.STATS && statistic && (
              <Stats
                analytics={statistic}
                onContentAnalyze={() => {
                  if (currentEditorState && !loading) {
                    setContentToAnalyze(getTextContent(currentEditorState));
                  }
                }}
              />
            )}
            {!showStructure &&
              initContent !== undefined &&
              documentTitle !== undefined &&
              documentHash &&
              activeMode !== Mode.STATS && (
                <Editor
                  initContent={initContent}
                  analytics={statistic}
                  onContentInit={(editorState: EditorState) => {
                    setCurrentEditorState(editorState);
                  }}
                  onContentChange={(editorState: EditorState, processImmediately?: boolean) => {
                    const content = getHtmlContent(editorState);
                    setNewContent(content);
                    setCurrentEditorState(editorState);
                    if (processImmediately === true) {
                      saveContent(documentId, content);
                    }
                  }}
                  onContentAnalyze={(editorState: EditorState) => {
                    if (activeMode !== Mode.WRITE) {
                      setContentToAnalyze(getTextContent(editorState));
                    }
                  }}
                  activeMode={activeMode}
                  title={documentTitle}
                  id={documentId}
                  documentHash={documentHash}
                  saveFlag={saving}
                  onDocNameChanged={() => {
                    readContent();
                  }}
                />
              )}
          </div>
          {sideBarVisible ? (
            <div className="hmr-sidebar">
              <div className="sidebar-container">
                <div className="collapse-sidebar">
                  <a
                    onClick={() => {
                      setSideBarVisible(!sideBarVisible);
                    }}
                  >
                    <img src={require('../../assets/images/collapse.svg')} alt=">>" />
                  </a>
                </div>
                <Sidebar
                  onShowStructureSwitch={(show: boolean) => {
                    if (show) {
                      if (currentEditorState) {
                        saveContent(documentId, getHtmlContent(currentEditorState));
                      }
                      setShowStructure(show);
                    } else {
                      if (myRef.current) {
                        myRef.current.goFade();
                      }
                    }
                  }}
                  switchMode={setActiveMode}
                  activeMode={activeMode}
                  statistic={statistic}
                  templateGuide={documentTemplateGuide}
                />
              </div>
            </div>
          ) : (
            <div className="sidebar-collapsed-container">
              <div className="collapse-sidebar">
                <a
                  onClick={() => {
                    setSideBarVisible(!sideBarVisible);
                  }}
                >
                  <img src={require('../../assets/images/collapse.svg')} alt=">>" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="progress-text-check">
          <p>Analyzing results...</p>
          <div className="ball-loader">
            <div className="ball-loader-ball ball2"></div>
            <div className="ball-loader-ball ball3"></div>
            <div className="ball-loader-ball ball1"></div>
          </div>
        </div>
      )}
    </MuiThemeProvider>
  );
}
