/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import React, { Component } from "react";

export class StyleButton extends Component<any, any> {
    onToggle = (e: any): void => {
        e.preventDefault();
        this.props.onToggle(this.props.style, this.props.type);
    }

    render(): JSX.Element {
        const { active, label, classes } = this.props;
        let className = classes;
        if (active) {
            className += ' active';
        }

        return (
            <span className={className} onClick={this.onToggle} > {label} </span>
        );
    }
}
