/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import React from 'react';
import { Router, Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { history } from '../../shared/helpers';
import './app.scss';
import { Login } from '../../auth/components/Login';
import LazyRegistration from '../../auth/components/Registration';
import LazyForgotPassword from '../../auth/components/ForgotPassword';
import { PrivateRoute } from '../../shared/helpers';
import Dashboard from '../dashboard/Dashboard';
import LazyDocuments from '../documents/Documents';
import { Callback } from '../../auth/components/Callback';
import LazyRecoveryPassword from '../../auth/components/RecoveryPassword';
import Profile from '../profile/Profile';
import { GlobalProvider } from '../shared/GlobalContext';
import Expired from '../profile/Expired';
import SharedDocument from '../dashboard/SharedDocument';
import { TourProvider } from '@reactour/tour';
import { ModalProvider } from 'modaaals';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { tourConfig } from './tour';

export default function App(props: any) {
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  return (
    <div className="app">
      <TourProvider
        steps={tourConfig}
        afterOpen={disableBody}
        beforeClose={enableBody}
        styles={{
          badge: base => ({ ...base, background: '#0967D2' }),
          //@ts-ignore
          dot: (base, { current }) => ({
            ...base,
            background: current ? '#0967D2' : '#DEEEFE',
          }),
          popover: base => ({
            ...base,
            background: '#ffffff',
            borderRadius: 10
          })
        }}
        scrollSmooth
      >
        <ModalProvider
          // modals={modals}
          styles={{
            contentInner: base => ({ ...base, margin: 50 })
          }}
          className="modaaals-modal"
          skipMotion
        >
          <BrowserRouter>
            <Router history={history}>
              <Switch>
                <Route exact path="/shared/:linkHash" component={SharedDocument} />
                <Route exact path="/registration" component={LazyRegistration} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/reset-password" component={LazyForgotPassword} />
                <Route exact path="/recovery-password" component={LazyRecoveryPassword} />
                <Route exact path="/auth/callback" component={Callback} />
                <GlobalProvider>
                  <PrivateRoute path="/expired" exact component={Expired} />
                  <PrivateRoute path="/profile" exact component={Profile} />
                  <PrivateRoute path="/dashboard" exact component={Dashboard} />
                  <PrivateRoute path="/" exact component={LazyDocuments} />
                </GlobalProvider>
                <Redirect to="/" />
              </Switch>
            </Router>
          </BrowserRouter>
        </ModalProvider>
      </TourProvider>
    </div>
  );
}
