import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './profile.scss';
import TopMenu from '../shared/TopMenu';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { PriceDesc, SubscriptionType, useGlobalState } from '../shared/GlobalContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ErrorData } from '../../shared';
import PromptModal from '../shared/PromptModal';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider } from '@material-ui/core';
import { materialTheme } from '../shared/MaterialTheme';
import { goToastError, goToastInfo } from '../shared/Toasts';
import { getErrorContent } from '../../shared/functions/process-error';
import { ToastContainer } from 'react-toastify';
import PaymentMethodForm from './PaymentMethodForm';
import {
  MsgCantCancelSubscription,
  MsgCantChangePassword,
  MsgCantGetDefaultPaymentMethod,
  MsgCantGetInvoicesList,
  MsgCantRecancelSubscription,
  MsgCantSendFeedback,
  MsgCantUpdatePlanToAnnual,
  MsgErrorPaymentMethodCreation,
  MsgErrorSubscriptionCreation,
  MsgNewPaymentMethodSet,
  MsgPasswordChangedSuccessfully,
  MsgReCancelSubscrptionPrompt,
  MsgThankYouForFeedback
} from '../../shared/constants/messages';
import CancelPlanModal from '../shared/CancelPlanModal';

const PasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Please enter your current password'),
  newPassword: Yup.string()
    .required('Please enter your new password')
    .matches(
      // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1}).*$/,
      // 'Password must contain at least 8 characters, one uppercase, one number and one special case character'
      'Password must contain at least 8 characters, one number and one special case character'
    ),
  repeatPassword: Yup.string()
    .required('Please confirm your new password')
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match.")
});
const FeedbackSchema = Yup.object().shape({
  feedbackText: Yup.string().required('Please enter your feedback')
});

export enum ProfileTabs {
  Account,
  Invoices,
  ResetPassword,
  Feedback,
  Help
}

interface SubscriptionPrompt {
  message: string;
  subscriptionId: string;
}

function formatDate(val: number | undefined): string {
  if (!val) {
    return '';
  }
  return new Date(val * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

export default function Profile(props: any) {
  const [tab, setTab] = useState<ProfileTabs>(ProfileTabs.Account);
  const {
    account,
    annualPrice,
    monthlyPrice,
    subscription,
    setSubscription,
    currentSubscriptionType,
    isFreeSubscription
  } = useGlobalState();
  const [selectedPrice, setSelectedPrice] = useState<PriceDesc | null>();
  const [updateProcessing, setUpdateProcessing] = useState<boolean>();
  const [cancelProcessing, setCancelProcessing] = useState<boolean>();
  const [reCancelProcessing, setReCancelProcessing] = useState<boolean>();
  const [feedbackProcessing, setFeedbackProcessing] = useState<boolean>();
  const [cancelSubscriptionPrompt, setCancelSubscriptionPrompt] = useState<boolean>(false);
  const [reCancelSubscriptionPrompt, setReCancelSubscriptionPrompt] = useState<SubscriptionPrompt | undefined>();
  const [upgradePlanPrompt, setUpgradePlanPrompt] = useState<string>();
  const [billingInfoMode, setBillingInfoMode] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<any[]>();
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<any>();
  const [showNewPaymentMethod, setShowNewPaymentMethod] = useState<boolean>(false);

  useEffect(() => {
    if (tab === ProfileTabs.Account) {
      setBillingInfoMode(false);
    }
  }, [tab]);
  const getPublicStripeKey = async () => axios.get(`/payment/public-key`);
  const getInvoices = async () => axios.get(`/payment/get-invoices`);
  const getDefaultPaymentMethod = async () => axios.get(`/payment/default-payment-method`);
  const provideFeedback = (feedbackText: string) =>
    axios.post(
      '/payment/feedback',
      JSON.stringify({
        feedbackText
      })
    );
  const updateSubscription = (subscriptionId: string, priceId: string) =>
    axios.post(
      '/payment/update-subscription',
      JSON.stringify({
        subscriptionId,
        priceId: priceId
      })
    );
  const cancelSubscription = (subscriptionId: string) =>
    axios.post(
      '/payment/cancel-subscription',
      JSON.stringify({
        subscriptionId
      })
    );
  const reCancelSubscription = (subscriptionId: string) =>
    axios.post(
      '/payment/recancel-subscription',
      JSON.stringify({
        subscriptionId
      })
    );
  const changePassword = ({ currentPassword, newPassword, repeatPassword }) =>
    axios.post(
      '/auth/local/update-password',
      JSON.stringify({
        currentPassword,
        newPassword,
        repeatPassword
      })
    );

  const stripePromise = getPublicStripeKey().then(keyRes => loadStripe(keyRes.data.publicKey));
  const onAnnualyPlanChanged = ev => {
    if (ev.currentTarget.value === 'on') {
      setSelectedPrice(annualPrice);
    }
  };
  const onMonthlyPlanChanged = ev => {
    if (ev.currentTarget.value === 'on') {
      setSelectedPrice(monthlyPrice);
    }
  };
  const isCancelled = () => subscription && subscription.cancel_at_period_end;
  const questBlock = (
    <p style={{ marginTop: 20, color: '#666' }}>
      Have any questions? Contact our support team:&nbsp;
      <a href="mailto:hello@homerwritingapp.com" style={{ textDecoration: 'underline' }}>
        hello@homerwritingapp.com
      </a>
    </p>
  );
  const cancelPlan = (
    <a
      href="#"
      onClick={e => {
        e.preventDefault();
        if (!cancelProcessing && subscription) {
          setCancelSubscriptionPrompt(true);
        }
      }}
      className="cancel-link"
    >
      {cancelProcessing ? 'Processing...' : 'Cancel plan'}
    </a>
  );
  const reCancelPlan = (
    <a
      href="#"
      onClick={e => {
        e.preventDefault();
        if (!cancelProcessing && subscription) {
          setReCancelSubscriptionPrompt({ message: MsgReCancelSubscrptionPrompt, subscriptionId: subscription?.id });
        }
      }}
      className="cancel-link"
    >
      {reCancelProcessing ? 'Processing...' : 'Resubscribe'}
    </a>
  );
  // const updatePlanToAnnual = (
  //   <a
  //     href="#"
  //     style={{ textDecoration: 'underline' }}
  //     onClick={e => {
  //       e.preventDefault();
  //       if (!updateProcessing && annualPrice && subscription) {
  //         setUpgradePlanPrompt(MsgUpgradePlanPrompt);
  //       }
  //     }}
  //   >
  //     {!updateProcessing && annualPrice ? `Update` : `Processing...`}
  //   </a>
  // );
  const billingPlans = (
    <div className="billing-plans">
      <div className="tr">
        <div className="td">
          <input
            type="radio"
            id="annualy"
            name="annualy_plan"
            checked={selectedPrice === annualPrice}
            onChange={onAnnualyPlanChanged}
          />
          <label htmlFor="annualy">Billed annualy</label>
        </div>
        <div className="td">
          <input
            type="radio"
            id="monthly"
            name="monthly_plan"
            checked={selectedPrice === monthlyPrice}
            onChange={onMonthlyPlanChanged}
          />
          <label htmlFor="monthly">Billed monthly</label>
        </div>
        <div className="td amount">
          <div className="amount-value">${selectedPrice?.amount}</div>
          <div className="plan-label">per {selectedPrice === annualPrice ? 'year' : 'month'}</div>
        </div>
      </div>
    </div>
  );
  const monthlyPlan = (
    <div className="billing-plans" style={{ marginTop: 40, marginBottom: 40 }}>
      <div className="tr">
        <div className="td">
          <input type="radio" id="monthly" name="monthly_plan" checked={true} />
          <label htmlFor="monthly">Billed monthly</label>
        </div>
        <div className="td amount">
          <div className="amount-value">{monthlyPrice?.amount || ''}$</div>
        </div>
      </div>
    </div>
  );
  const annualPlan = (
    <div className="billing-plans" style={{ marginTop: 40, marginBottom: 40 }}>
      <div className="tr">
        <div className="td">
          <input type="radio" id="annualy" name="annualy_plan" checked={true} />
          <label htmlFor="annualy">Billed annualy</label>
        </div>
        <div className="td amount">
          <div className="amount-value">{annualPrice?.amount || ''}$</div>
        </div>
      </div>
    </div>
  );
  const showDefaultPaymentMethod = (last4, expMonth, expYear, brand) => (
    <div className="billing-plans">
      <div className="tr">
        <div className="td">
          <input type="radio" id="annualy" name="annualy_plan" checked={true} />
          <label className="card-text" htmlFor="annualy">
            **** **** **** {last4}
          </label>
          <div className="vert-divider"></div>
          <span className="card-text">
            {expMonth} / {expYear}
          </span>
        </div>
        <div className="td amount">
          {brand === 'visa' && (
            <div className="amount-value">
              <img src={require('../../assets/images/visa.svg')} alt="Visa" />
            </div>
          )}
          {brand === 'mastercard' && (
            <div className="amount-value">
              <img src={require('../../assets/images/mastercard.svg')} alt="Mastercard" />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const profileTabs = (
    <div className="profile-tabs">
      <div className="tr">
        <div
          onClick={() => {
            setTab(ProfileTabs.Account);
          }}
          className={'td ' + (tab === ProfileTabs.Account ? 'active' : '')}
        >
          {tab === ProfileTabs.Account ? (
            <img src={require('../../assets/images/t-account.svg')} />
          ) : (
            <img src={require('../../assets/images/m-account.svg')} />
          )}
          Plan & Payment
        </div>
        <div
          onClick={() => {
            setTab(ProfileTabs.Invoices);
          }}
          className={'td ' + (tab === ProfileTabs.Invoices ? 'active' : '')}
        >
          {tab === ProfileTabs.Invoices ? (
            <img src={require('../../assets/images/t-invoices.svg')} />
          ) : (
            <img src={require('../../assets/images/m-invoices.svg')} />
          )}
          Invoices
        </div>
        {!account?.isRegisteredByGoogleOrLinkedIn && (
          <div
            onClick={() => {
              setTab(ProfileTabs.ResetPassword);
            }}
            className={'td ' + (tab === ProfileTabs.ResetPassword ? 'active' : '')}
          >
            {tab === ProfileTabs.ResetPassword ? (
              <img src={require('../../assets/images/t-reset-pwd.svg')} />
            ) : (
              <img src={require('../../assets/images/m-reset-pwd.svg')} />
            )}
            Reset Password
          </div>
        )}
        <div
          onClick={() => {
            setTab(ProfileTabs.Feedback);
          }}
          className={'td ' + (tab === ProfileTabs.Feedback ? 'active' : '')}
        >
          {tab === ProfileTabs.Feedback ? (
            <img src={require('../../assets/images/t-feedback.svg')} />
          ) : (
            <img src={require('../../assets/images/m-feedback.svg')} />
          )}
          Feedback
        </div>
        <div
          onClick={() => {
            setTab(ProfileTabs.Help);
          }}
          className={'td ' + (tab === ProfileTabs.Help ? 'active' : '')}
        >
          {tab === ProfileTabs.Help ? (
            <img src={require('../../assets/images/t-feedback.svg')} />
          ) : (
            <img src={require('../../assets/images/m-feedback.svg')} />
          )}
          Help
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (props.history.location && props.history.location.state) {
      setTab(props.history.location.state.initialTab || ProfileTabs.Account);
    }
  }, [props.history.location]);

  useEffect(() => {
    if (!selectedPrice && annualPrice) {
      setSelectedPrice(annualPrice);
    }
  }, [annualPrice]);

  useEffect(() => {
    getInvoices()
      .then(res => {
        setInvoices(res.data.data);
      })
      .catch((error: { response: { data: ErrorData } } | ErrorData) => {
        setInvoices([]);
        goToastError(getErrorContent(error, MsgCantGetInvoicesList));
      });
  }, []);

  useEffect(() => {
    if (subscription) {
      getDefaultPaymentMethod()
        .then(res => {
          setDefaultPaymentMethod(res.data);
        })
        .catch((error: { response: { data: ErrorData } } | ErrorData) => {
          setDefaultPaymentMethod(undefined);
          goToastError(getErrorContent(error, MsgCantGetDefaultPaymentMethod));
        });
    }
  }, [subscription]);

  return (
    <MuiThemeProvider theme={materialTheme}>
      <ToastContainer closeButton={false} />
      <TopMenu showDocuments={true} />
      <div className="profile-root">
        <div style={{ display: 'block' }}>{profileTabs}</div>
        <div className="account-details">
          {tab === ProfileTabs.Account && billingInfoMode && defaultPaymentMethod && (
            <div className="profile-content-info">
              <div className="header-labels">
                <h3 style={{ marginBottom: 40 }}>Plan & Payment</h3>
                <div style={{ marginBottom: 40 }}>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setBillingInfoMode(false);
                    }}
                  >
                    &lt;&lt; back to your account
                  </a>
                </div>
                <h6 style={{ marginBottom: 20 }}>Billing info</h6>
                {showDefaultPaymentMethod(
                  defaultPaymentMethod.card.last4,
                  defaultPaymentMethod.card.exp_month,
                  defaultPaymentMethod.card.exp_year,
                  defaultPaymentMethod.card.brand
                )}
                <div className="new-pm-block">
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setShowNewPaymentMethod(!showNewPaymentMethod);
                    }}
                  >
                    <img src={require('../../assets/images/plus.svg')} />
                    Update credit card
                  </a>
                  <div style={{ marginTop: 30 }}></div>
                </div>
                {showNewPaymentMethod && (
                  <div>
                    <Elements stripe={stripePromise}>
                      <PaymentMethodForm
                        onComplete={(err: string | undefined) => {
                          if (!err) {
                            getDefaultPaymentMethod()
                              .then(res => {
                                setDefaultPaymentMethod(res.data);
                                setBillingInfoMode(false);
                                goToastInfo(MsgNewPaymentMethodSet);
                              })
                              .catch((error: { response: { data: ErrorData } } | ErrorData) => {
                                setDefaultPaymentMethod(undefined);
                                goToastError(getErrorContent(error, MsgCantGetDefaultPaymentMethod));
                              });
                          } else {
                            goToastError(MsgErrorPaymentMethodCreation(err));
                          }
                        }}
                      />
                    </Elements>
                  </div>
                )}
              </div>
            </div>
          )}
          {tab === ProfileTabs.Account && !billingInfoMode && (
            <div className="profile-content-info">
              <div className="header-labels">
                <h3>Plan & Payment</h3>
                {currentSubscriptionType !== SubscriptionType.Undefined &&
                  currentSubscriptionType === SubscriptionType.Free && (
                    <h6 style={{ marginTop: 40 }}>Upgrade for Uninterrupted Access</h6>
                  )}
              </div>
              {currentSubscriptionType === SubscriptionType.Free && selectedPrice && (
                <>
                  {billingPlans}
                  <div>
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        priceId={selectedPrice.id}
                        priceAmount={selectedPrice.amount}
                        onComplete={(err: string | undefined, subscription?: any) => {
                          if (!err && subscription) {
                            setSubscription(subscription);
                          } else {
                            goToastError(MsgErrorSubscriptionCreation(err || ''));
                          }
                        }}
                      />
                    </Elements>
                  </div>
                </>
              )}
              {currentSubscriptionType === SubscriptionType.Monthly && annualPrice && (
                <div>
                  <div className="current-plan-info">
                    <div>
                      You are currently on the <span className="plan-colored-label monthly-label">Monthly plan</span>
                    </div>
                    <div className="update-billing">
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setBillingInfoMode(true);
                        }}
                        style={{ textDecoration: 'underline' }}
                      >
                        Update credit card
                      </a>
                    </div>
                  </div>
                  {monthlyPlan}
                  {!isCancelled() ? (
                    <>
                      {/* <div className="block-divider"></div> */}
                      <div className="block-2-sect">
                        <div>Your plan will renew on {formatDate(subscription?.current_period_end)}.</div>
                        {/* <div>{cancelPlan}</div> */}
                        <div>&nbsp;</div>
                      </div>
                      {/* <div className="block-2-sect">
                        <div>You can update your plan to annual ({annualPrice.amount}$)</div>
                        <div>{updatePlanToAnnual}</div>
                      </div> */}
                    </>
                  ) : (
                    <div className="block-2-sect">
                      <div>Your plan will end on {formatDate(subscription?.current_period_end)}.</div>
                      <div>{reCancelPlan}</div>
                    </div>
                  )}
                </div>
              )}
              {currentSubscriptionType === SubscriptionType.Annual && (
                <div>
                  <div className="current-plan-info">
                    <div>
                      You are currently on the <span className="plan-colored-label annual-label">Annual plan</span>
                    </div>
                    <div className="update-billing">
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setBillingInfoMode(true);
                        }}
                        style={{ textDecoration: 'underline' }}
                      >
                        Update credit card
                      </a>
                    </div>
                  </div>
                  {annualPlan}
                  {!isCancelled() ? (
                    <>
                      {/* <div className="block-divider"></div> */}
                      <div className="block-2-sect">
                        <div>Your plan will renew on {formatDate(subscription?.current_period_end)}.</div>
                        {/* <div>{cancelPlan}</div> */}
                        <div>&nbsp;</div>
                      </div>
                    </>
                  ) : (
                    <div className="block-2-sect">
                      <div>Your plan will end on {formatDate(subscription?.current_period_end)}.</div>
                      <div>{reCancelPlan}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {tab === ProfileTabs.Invoices && (
            <div className="profile-content-info">
              <div className="header-labels">
                <h3>Invoices</h3>
              </div>
              <div className="invoices-content-container">
                {(!invoices || invoices.length <= 0) && (
                  <div style={{ marginTop: 15 }}>We don't have any invoices to show at the moment.</div>
                )}
                {invoices && (
                  <div className="invoices-table">
                    {invoices.map(invoice => (
                      <div key={invoice.id} className="tr">
                        <div className="td">{formatDate(+invoice.created)}</div>
                        <div className="td">{invoice.amount / 100}$</div>
                        <div className="td">
                          <a href={invoice.receipt_url} target="_blank">
                            more &gt;&gt;
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
          {tab === ProfileTabs.ResetPassword && (
            <div className="profile-content-info">
              <Formik
                validateOnChange={true}
                validateOnBlur={true}
                initialValues={{
                  currentPassword: '',
                  newPassword: '',
                  repeatPassword: ''
                }}
                validationSchema={PasswordSchema}
                onSubmit={(values, { resetForm }) => {
                  changePassword(values)
                    .then(() => {
                      resetForm();
                      goToastInfo(MsgPasswordChangedSuccessfully);
                    })
                    .catch((error: { response: { data: ErrorData } } | ErrorData) => {
                      goToastError(getErrorContent(error, MsgCantChangePassword));
                    });
                }}
              >
                {({ errors }) => (
                  <Form>
                    <div className="header-labels">
                      <h3>Reset Password</h3>
                    </div>
                    <div>
                      <Field
                        name="currentPassword"
                        type="password"
                        className="generic-input password-input"
                        placeholder="Current password"
                      />
                      <div><ErrorMessage name="currentPassword" /></div>
                      <Field
                        name="newPassword"
                        type="password"
                        className="generic-input password-input"
                        placeholder="New password"
                      />
                      <div><ErrorMessage name="newPassword" /></div>
                      <Field
                        name="repeatPassword"
                        type="password"
                        className="generic-input password-input"
                        placeholder="Confirm new password"
                      />
                      <div><ErrorMessage name="repeatPassword" /></div>
                    </div>
                    <div className="reset-pwd-actions">
                      <Button
                        disabled={!!errors.currentPassword || !!errors.newPassword || !!errors.repeatPassword}
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {tab === ProfileTabs.Feedback && (
            <div className="profile-content-info">
              <Formik
                validateOnChange={true}
                validateOnBlur={true}
                initialValues={{
                  feedbackText: ''
                }}
                validationSchema={FeedbackSchema}
                onSubmit={(values, { resetForm }) => {
                  setFeedbackProcessing(true);
                  provideFeedback(values.feedbackText)
                    .then(() => {
                      resetForm();
                      goToastInfo(MsgThankYouForFeedback);
                    })
                    .catch((error: { response: { data: ErrorData } } | ErrorData) => {
                      goToastError(getErrorContent(error, MsgCantSendFeedback));
                    })
                    .finally(() => {
                      setFeedbackProcessing(false);
                    });
                }}
              >
                {({ errors }) => (
                  <Form>
                    <div className="header-labels">
                      <h3>Feedback</h3>
                      <div className="feedback-desc">
                        Your feedback is very important to us. If you have any questions or would like to recommend a
                        feature, please let us know:
                      </div>
                    </div>
                    <div className="feedback-container">
                      <Field
                        component="textarea"
                        name="feedbackText"
                        placeholder="Comments"
                        className="generic-input"
                      />
                    </div>
                    <ErrorMessage name="feedbackText" />
                    <div className="feedback-actions">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!!errors.feedbackText || feedbackProcessing}
                        className="feedback-button"
                      >
                        {feedbackProcessing ? 'Wait please...' : 'Send feedback'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {tab === ProfileTabs.Help && (
            <div className="profile-content-info">
              <div className="header-labels">
                <h3>Help</h3>
              </div>
              <div className="feedback-container">
                {questBlock}
                {!isCancelled() && !isFreeSubscription && <div style={{ marginTop: 40 }}>{cancelPlan}</div>}
              </div>
            </div>
          )}
        </div>
      </div>
      <CancelPlanModal
        show={cancelSubscriptionPrompt}
        setShow={setCancelSubscriptionPrompt}
        tillDate={formatDate(subscription?.current_period_end)}
        okAcion={() => {
          setCancelProcessing(true);
          cancelSubscription(subscription?.id || '')
            .then(res => {
              setSubscription(res.data);
            })
            .catch((error: { response: { data: ErrorData } } | ErrorData) => {
              goToastError(getErrorContent(error, MsgCantCancelSubscription));
            })
            .finally(() => {
              setCancelProcessing(false);
            });
        }}
      />
      <PromptModal
        message={reCancelSubscriptionPrompt?.message}
        setMessage={setReCancelSubscriptionPrompt}
        okAcion={() => {
          setReCancelProcessing(true);
          reCancelSubscription(reCancelSubscriptionPrompt?.subscriptionId || '')
            .then(res => {
              setSubscription(res.data);
            })
            .catch((error: { response: { data: ErrorData } } | ErrorData) => {
              goToastError(getErrorContent(error, MsgCantRecancelSubscription));
            })
            .finally(() => {
              setReCancelProcessing(false);
            });
        }}
      />
      <PromptModal
        message={upgradePlanPrompt}
        setMessage={setUpgradePlanPrompt}
        okAcion={() => {
          if (subscription && annualPrice) {
            setUpdateProcessing(true);
            updateSubscription(subscription.id, annualPrice.id)
              .then(sbs => {
                setSubscription(sbs.data);
              })
              .catch((error: { response: { data: ErrorData } } | ErrorData) => {
                goToastError(getErrorContent(error, MsgCantUpdatePlanToAnnual));
              })
              .finally(() => {
                setUpdateProcessing(false);
              });
          }
        }}
      />
    </MuiThemeProvider>
  );
}
