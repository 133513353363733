import React, { useState } from 'react';
import axios from 'axios';
import './checkoutForm.scss';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';

const options = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

export default function PaymentMethodForm(props: { onComplete: (err: string | undefined) => void }) {
  const { onComplete } = props;
  const [processing, setProcessing] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  function setDefaultPaymentMethod(paymentMethodId: string) {
    return axios
      .post(
        '/payment/set-default-payment-method',
        JSON.stringify({
          paymentMethodId: paymentMethodId
        })
      )
      .then(() => {
        onComplete(undefined);
      })
      .catch(error => {
        const stderr = 'Something went wrong with your payment';
        if (error.response && error.response.data && error.response.data.error) {
          onComplete(error.response.data.error.message || stderr);
        } else {
          onComplete(stderr);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setProcessing(true);
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement as any
    });
    if (error || !paymentMethod) {
      setProcessing(false);
      onComplete(error?.message || 'payment method is missing');
    } else {
      setDefaultPaymentMethod(paymentMethod.id);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="card-holder-input card-input">
        <CardElement className="sr-input sr-card-element" options={options} />
      </div>
      <div className="payment-actions">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="generic-action-button confirm-button"
          disabled={!stripe || processing}
        >
          {processing ? 'Processing...' : 'Set a card'}
        </Button>
      </div>
    </form>
  );
}
